export default defineNuxtRouteMiddleware((to, from) => {
  const {isContributor, isReadOnly} = useRole();

  const originShouldRedirect = from.name === 'auth-login' || from.name === undefined;
  const destinationShouldRedirect = to.name === 'index';
  const roleShouldRedirect = isContributor.value || isReadOnly.value;

  if (originShouldRedirect && destinationShouldRedirect && roleShouldRedirect) {
    return navigateTo({name: 'projecten'});
  }
});
