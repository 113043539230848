<template>
  <component
    :is="to ? NuxtLink : 'button'"
    class="inline-flex cursor-pointer items-center justify-center gap-1.5 font-medium no-underline transition disabled:cursor-not-allowed disabled:opacity-30 disabled:saturate-0 print:hidden"
    :class="[
      type === 'submit' ? colorVariants['primary'] : colorVariants[type],
      {'size-11 !p-0': type !== 'link' && !label, 'size-8 !p-0': type === 'link' && !label},
      {'opacity-30 saturate-0' : disabled},
      type === 'link' ? 'rounded-lg p-2 text-xs' : 'rounded-2xl p-2.5 text-sm leading-relaxed',
    ]"
    :disabled="disabled || loading ? 'disabled' : undefined"
    :to="localePath(to)"
    :type="type === 'submit' ? 'submit' : 'button'"
  >
    <slot>
      <div
        v-if="iconPrefix || label"
        class="flex items-center justify-center gap-1.5"
        :class="{'-mt-0.5' : type !== 'link'}"
      >
        <UiIcon
          v-if="iconPrefix"
          :name="iconPrefix"
          :size="iconPrefixSize"
        />
        <div v-if="label">
          {{ label }}
        </div>
      </div>
      <UiIcon
        v-if="icon"
        :name="icon"
        :size="iconSize"
      />
    </slot>
    <UiIcon
      v-if="loading"
      :size="iconSize"
      name="svg-spinners:ring-resize"
    />
  </component>
</template>

<script lang="ts" setup>
import {NuxtLink} from '#components';
import type {RouteLocationRaw} from 'vue-router';

const colorVariants = {
  primary: 'bg-primary hover:bg-primary-700 hover:border-primary-700 active:bg-primary-800 active:border-primary-900 text-white border border-primary border-t-[3px]',
  secondary: 'bg-blue-500 hover:border-blue-700 hover:bg-blue-700 active:bg-blue-800 active:border-blue-900 text-white border border-blue-500 border-t-[3px]',
  soft: 'bg-primary-50 hover:bg-primary-100 hover:border-primary-100 active:bg-primary-200 active:border-primary-300 text-primary-700 border border-primary-50 border-t-[3px]',
  outline: 'border-gray-200 text-gray-500 hover:bg-gray-50 border bg-white p-3',
  link: 'text-primary-700 text-xs font-medium hover:bg-primary-50 hover:text-primary',
  success: 'bg-success hover:bg-success-700 hover:border-success-700 active:bg-success-800 active:border-success-900 text-white border border-success border-t-[3px]',
};

withDefaults(defineProps<{
  disabled?: boolean
  icon?: string
  iconPrefix?: string
  iconPrefixSize?: 'tiny' | 'small' | 'medium' | 'large'
  iconSize?: 'tiny' | 'small' | 'medium' | 'large'
  label?: string
  loading?: boolean
  to?: RouteLocationRaw,
  type?: 'primary' | 'secondary' | 'soft' | 'outline' | 'submit' | 'link' | 'success'
}>(), {
  disabled: false,
  icon: undefined,
  iconPrefix: undefined,
  iconPrefixSize: 'small',
  iconSize: 'small',
  label: undefined,
  loading: false,
  to: undefined,
  type: 'primary',
});
</script>
