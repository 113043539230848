import {nl} from '@formkit/i18n';
import {createConfig} from '@formkit/core';
import {generateClasses} from '@formkit/themes';
import {formKitIcons} from '~/utils/icon';
import {createFormKitInputsPlugin} from '@kgierke/formkit-inputs';
import {createProPlugin, inputs} from '@formkit/pro-unlimited';
import {createInput} from '@formkit/vue';
import {FormkitSignature} from '#components';

const formKitInputPlugin = createFormKitInputsPlugin();

export default createConfig({
  plugins: [
    createProPlugin('fk-cc1ce329d1', inputs),
    formKitInputPlugin,
  ],
  locales: {nl},
  locale: 'nl',
  icons: {
    ...formKitIcons,
  },
  inputs: {
    signature: createInput(FormkitSignature),
  },
  config: {
    classes: generateClasses({
      // Global styles apply to _all_ inputs with matching section keys
      global: {
        fieldset: 'max-w-md border border-gray-400 rounded px-2 pb-1',
        help: 'text-xs text-gray-500',
        inner: 'relative formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none rounded-2xl overflow-clip transition',
        input: 'focus:outline-none focus:ring-0 focus:shadow-none text-sm transition',
        label: 'block mb-1 text-xs font-medium',
        legend: 'font-bold text-sm',
        loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
        message: 'text-red-500 mb-1 text-xs',
        messages: 'list-none p-0 mt-1 mb-0',
        outer: 'mb-4 formkit-disabled:opacity-50',
        prefixIcon: '[&>svg]:size-5',
        suffixIcon: '[&>svg]:size-5 p-3 -m-3 absolute right-4',
      },

      // Family styles apply to all inputs that share a common family
      'family:box': {
        decorator: 'block relative h-5 w-5 mr-2 rounded ring-1 ring-gray-400 peer-checked:ring-blue-700 text-transparent peer-checked:text-white peer-checked:bg-blue-500',
        decoratorIcon: 'flex p-[2px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
        help: 'mb-2 mt-1.5',
        input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
        inner: '$reset $remove:formkit-disabled:bg-gray-200',
        label: '$reset text-sm text-gray-700 mt-1 select-none',
        wrapper: 'flex items-center mb-1',
      },
      'family:button': {
        input: '$reset leading-relaxed w-full p-2.5 pt-2 text-sm inline-block cursor-pointer rounded-2xl font-medium no-underline transition disabled:cursor-not-allowed disabled:opacity-30 disabled:saturate-0 print:hidden bg-primary hover:bg-primary-700 hover:border-primary-700 focus:bg-primary-800 focus:border-primary-900 text-white border border-primary border-t-[3px] formkit-disabled:bg-gray-400 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
        wrapper: 'mb-1',
        prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
        suffixIcon: '$reset block w-4 ml-2 stretch',
      },
      'family:dropdown': {
        dropdownWrapper: 'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
        emptyMessageInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
        inner: 'max-w-md relative flex ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 rounded mb-1 formkit-disabled:focus-within:ring-gray-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-blue-500',
        input: 'w-full px-3 py-2',
        listbox: 'shadow-lg rounded overflow-hidden',
        listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
        listitem: 'pl-7 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 aria-selected:bg-blue-600 aria-selected:text-white data-[is-active="true"]:aria-selected:bg-blue-600 data-[is-active="true"]:aria-selected:bg-blue-700',
        loaderIcon: 'ml-auto',
        loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
        option: 'p-2.5',
        optionLoading: 'pl-2.5 text-gray-400',
        placeholder: 'p-2.5 text-gray-500',
        selector: 'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
        selection: 'flex w-full',
        selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
        selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
      },
      'family:text': {
        inner: 'flex items-center sm:max-w-md ring-1 ring-gray-200 hover:ring-gray-400 focus-within:!ring-gray-900 [&>label:first-child]:focus-within:text-blue-500 rounded mb-1',
        input: 'w-full px-3 py-3 border-none text-base text-gray-700 placeholder-gray-500',
      },
      'family:date': {
        inner: 'flex items-center max-w-md ring-1 ring-gray-400 focus-within:ring-blue-500 focus-within:ring-2 [&>label:first-child]:focus-within:text-blue-500 rounded mb-1',
        input: 'w-full px-3 py-2 border-none text-gray-700 placeholder-gray-500',
      },
      // Specific styles apply only to a given input type
      color: {
        inner: 'flex max-w-[5.5em] w-full formkit-prefix-icon:max-w-[7.5em] formkit-suffix-icon:formkit-prefix-icon:max-w-[10em]',
        input: '$reset appearance-none w-full cursor-pointer border-none rounded p-0 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none',
        suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto',
      },
      file: {
        fileItem: 'flex w-full items-center text-gray-800 mb-1 last:mb-0',
        fileItemIcon: 'w-4 mr-2 shrink-0',
        fileList: 'flex w-full h-full text-xs items-end px-3 py-2 text-gray-400 formkit-multiple:data-[has-multiple="true"]:mb-6',
        fileName: 'break-all grow text-ellipsis',
        fileRemove: 'relative flex items-center z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-blue-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
        fileRemoveIcon: 'block text-base w-4 h-4 relative z-[2]',
        inner: 'relative max-w-md p-1 cursor-pointer formkit-multiple:[&>button]:absolute bg-white',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
        noFiles: 'flex bg-primary rounded-xl text-white text-xs items-end p-3',
        noFilesIcon: 'w-4 mr-2',
      },
      image: {
        label: 'font-medium text-xs mb-2',
        outer: 'h-full',
        inner: 'p-2.5 text-xs hover:bg-primary-50 hover:border-primary grow rounded-2xl border border-gray-200 relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute bg-white text-transparent',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
        wrapper: 'flex flex-col h-full',
        fileList: 'absolute inset-0 size-full bg-white p-1 z-30 flex flex-wrap gap-1 overflow-y-scroll select-none cursor-default',
        fileItem: 'size-full z-20 relative flex-1 basis-1/3', // this somehow creates a two-column layout that falls back to one column if necessary
        fileItemImage: 'size-full object-contain pointer-events-none',
        fileItemRemove: 'absolute top-2 left-2 p-1 rounded bg-primary text-white',
        fileItemName: 'hidden',
      },
      radio: {
        decorator: 'rounded-full',
        decoratorIcon: 'w-5 p-[5px]',
      },
      range: {
        inner: '$reset flex items-center max-w-md',
        input: '$reset w-full mb-1 h-2 p-0 rounded-full',
        prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
        suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      search: {
        inner: 'bg-gray-100 ring-gray-100',
        input: 'bg-gray-100',
        prefixIcon: 'pl-3',
      },
      select: {
        inner: 'transition border w-full border-gray-100 px-3 hover:border-gray-400 flex relative sm:max-w-md items-center rounded mb-1 [&>span:first-child]:focus-within:text-blue-500 bg-white',
        input: 'cursor-pointer w-full pr-4 py-3 text-base text-gray-900 placeholder-gray-400 formkit-multiple:p-0 formkit-multiple:data-[placeholder="true"]:text-inherit appearance-none',
        option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
      },
      textarea: {
        inner: 'flex max-w-md mb-1 [&>label:first-child]:focus-within:text-blue-500',
        input: 'block w-full h-32 px-3 py-3 text-base text-gray-700 placeholder-gray-400 focus:shadow-outline focus-within:border-blue-500 hover:border-gray-400 focus-within:!border-gray-900 border border-gray-200 rounded-2xl bg-gray-100',
      },
      toggle: {
        altLabel: 'w-full mb-1 text-xs font-medium',
        inner: '$reset inline-block mr-2',
        input: 'peer absolute opacity-0 pointer-events-none',
        innerLabel: 'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
        thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
        track: 'p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-400 peer-checked:bg-primary peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
        valueLabel: 'font-bold text-sm',
        wrapper: 'flex flex-wrap items-center mb-1',
      },
    }),
  },
});
