import L from 'leaflet';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MiniMap from 'leaflet-minimap';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      Leaflet: L,
      MiniMap,
    },
  };
});
