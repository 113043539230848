import {File, Image} from '~/types/sofie'
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string | number; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  Iban: { input: string; output: string; }
  Image: { input: Image; output: Image; }
  JSON: { input: any; output: any; }
  Mixed: { input: any; output: any; }
  Null: { input: null; output: null; }
  PrivateFile: { input: any; output: any; }
  PublicFile: { input: any; output: any; }
  Upload: { input: any; output: any; }
  Uuid: { input: string; output: string; }
};

export type ActivityFilters = {
  organization?: InputMaybe<Scalars['Uuid']['input']>;
  project?: InputMaybe<Scalars['Uuid']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ActivityType>;
  user?: InputMaybe<Scalars['Uuid']['input']>;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  created_at: Scalars['DateTime']['output'];
  drawing?: Maybe<Drawing>;
  location?: Maybe<Location>;
  organization?: Maybe<Organization>;
  project?: Maybe<Project>;
  type: ActivityType;
  user?: Maybe<User_Bc2e2ba7cc62f36b5c95747d164af31c>;
  uuid: Scalars['Uuid']['output'];
};

export enum ActivityType {
  ChangeRequest = 'ChangeRequest',
  NewDrawing = 'NewDrawing',
  NewVersion = 'NewVersion',
  UpdatedDrawing = 'UpdatedDrawing'
}

export type Address = {
  __typename?: 'Address';
  address_string?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  geodata?: Maybe<Scalars['JSON']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  house_number_suffix?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  street: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  house_number_suffix?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  township?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type Capability = {
  __typename?: 'Capability';
  name: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  active_image?: Maybe<Scalars['Image']['output']>;
  ancestors: Array<Category>;
  capabilities: Array<Capability>;
  children: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  descendants: Array<Category>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<Category>;
  parent_id?: Maybe<Scalars['Int']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ChangeRequest = {
  __typename?: 'ChangeRequest';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  is_sent: Scalars['Boolean']['output'];
  links: Array<Marker>;
  links_count?: Maybe<Scalars['Int']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
  suggestions: Array<Marker>;
  suggestions_count?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type Discipline = {
  __typename?: 'Discipline';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Drawing = {
  __typename?: 'Drawing';
  capabilities: Array<Capability>;
  confidential?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discipline: Discipline;
  floor: Floor;
  latest_version?: Maybe<DrawingVersion>;
  location: Location;
  locations: Array<Location>;
  project: Project;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type: Type;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<Array<User>>;
  uuid: Scalars['Uuid']['output'];
  version?: Maybe<Scalars['String']['output']>;
  versions: Array<DrawingVersion>;
  versions_count?: Maybe<Scalars['Int']['output']>;
};

export type DrawingFilters = {
  discipline?: InputMaybe<Scalars['ID']['input']>;
  floor?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ID']['input']>;
};

export type DrawingVersion = {
  __typename?: 'DrawingVersion';
  capabilities: Array<Capability>;
  change_requests: Array<ChangeRequest>;
  change_requests_count?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  drawing: Array<Scalars['PrivateFile']['output']>;
  file: Array<Scalars['PrivateFile']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type EntityAccess = {
  __typename?: 'EntityAccess';
  accessible: Scalars['Boolean']['output'];
  entities?: Maybe<Array<EntityAccess>>;
  title: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};

export type Floor = {
  __typename?: 'Floor';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Form = {
  __typename?: 'Form';
  fields: Array<FormField>;
  id: Scalars['Int']['output'];
  schema: Scalars['JSON']['output'];
  schema_v2: Scalars['Mixed']['output'];
  slug: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type FormData = {
  __typename?: 'FormData';
  fields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
};

export type FormField = {
  __typename?: 'FormField';
  fields: Array<FormField>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique?: Maybe<Scalars['Boolean']['output']>;
};

export type FormFieldAttachment = {
  attachment: Scalars['Upload']['input'];
  field_name: Scalars['String']['input'];
};

export type FormSubmitResponse = {
  __typename?: 'FormSubmitResponse';
  confirm_url?: Maybe<Scalars['String']['output']>;
};

export enum LocaleEnum {
  Nl = 'nl'
}

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  drawings: Array<Drawing>;
  drawings_count?: Maybe<Scalars['Int']['output']>;
  projects: Array<Project>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  total_suggestions_count: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type Marker = {
  __typename?: 'Marker';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  comment?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  drawing?: Maybe<Drawing>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['Image']['output']>;
  images: Array<Scalars['Image']['output']>;
  is_sent: Scalars['Boolean']['output'];
  /** Pagina indexering begint vanaf 0 */
  page_index?: Maybe<Scalars['Int']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  reporter?: Maybe<User_Bc2e2ba7cc62f36b5c95747d164af31c>;
  status?: Maybe<Scalars['String']['output']>;
  symbol?: Maybe<Scalars['Image']['output']>;
  title: Scalars['String']['output'];
  type: MarkerType;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export enum MarkerType {
  Link = 'Link',
  Suggestion = 'Suggestion'
}

export type Menu = {
  __typename?: 'Menu';
  items: Array<MenuItem>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  capability?: Maybe<Scalars['String']['output']>;
  children: Array<MenuItem>;
  /** @deprecated Use `capability` instead. */
  scope?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<Scalars['Boolean']['output']>;
  changePassword?: Maybe<Scalars['Null']['output']>;
  createDrawing: Drawing;
  createLocation?: Maybe<Scalars['Null']['output']>;
  createMarker: Marker;
  createOrganization?: Maybe<Scalars['Null']['output']>;
  createProject?: Maybe<Scalars['Null']['output']>;
  createUser: User_22881be7471d45df81e4081199322c52;
  deleteDrawing?: Maybe<Scalars['Null']['output']>;
  deleteLocation?: Maybe<Scalars['Null']['output']>;
  deleteMarker?: Maybe<Scalars['Null']['output']>;
  deleteOrganization?: Maybe<Scalars['Null']['output']>;
  deleteProject?: Maybe<Scalars['Null']['output']>;
  deleteUser?: Maybe<Scalars['Null']['output']>;
  disableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  enableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  generateTwoFactorSecret?: Maybe<TwoFactorSecret>;
  lockDrawing: Scalars['Boolean']['output'];
  login?: Maybe<Scalars['String']['output']>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  manageUserAccess?: Maybe<Scalars['Null']['output']>;
  publishDrawing: Scalars['Boolean']['output'];
  requestPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  requestUserPasswordReset?: Maybe<Scalars['Null']['output']>;
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  retryProcessingDrawing?: Maybe<Scalars['Null']['output']>;
  submitChangeRequest: ChangeRequest;
  submitForm: FormSubmitResponse;
  updateDrawing: Drawing;
  updateLocation: Location;
  updateMarkerStatus: Marker;
  updateOrganization?: Maybe<Scalars['Null']['output']>;
  updateProject: Project;
  updateUser: User_22881be7471d45df81e4081199322c52;
  updateUserRole?: Maybe<Scalars['Null']['output']>;
  uploadNewDrawingVersion?: Maybe<Scalars['Null']['output']>;
};


export type MutationAcceptInviteArgs = {
  email: Scalars['Email']['input'];
  invite: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  current_password: Scalars['String']['input'];
  new_password: Scalars['String']['input'];
  new_password_confirm: Scalars['String']['input'];
};


export type MutationCreateDrawingArgs = {
  confidential?: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  discipline: Scalars['ID']['input'];
  file?: InputMaybe<Scalars['Upload']['input']>;
  floor: Scalars['ID']['input'];
  location: Scalars['Uuid']['input'];
  published?: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  type: Scalars['ID']['input'];
};


export type MutationCreateLocationArgs = {
  address: AddressInput;
  project: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateMarkerArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  coordinates: Array<Scalars['String']['input']>;
  images?: InputMaybe<Array<Scalars['Upload']['input']>>;
  linked_drawing?: InputMaybe<Scalars['Uuid']['input']>;
  page_index: Scalars['Int']['input'];
  symbol?: InputMaybe<Scalars['String']['input']>;
  type: MarkerType;
  uuid: Scalars['Uuid']['input'];
};


export type MutationCreateOrganizationArgs = {
  address: AddressInput;
  title: Scalars['String']['input'];
};


export type MutationCreateProjectArgs = {
  organization_uuid: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  email: Scalars['Email']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  organization?: InputMaybe<Scalars['Uuid']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserRole>;
};


export type MutationDeleteDrawingArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type MutationDeleteLocationArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type MutationDeleteMarkerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type MutationDeleteProjectArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type MutationDeleteUserArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type MutationEnableTwoFactorArgs = {
  otp: Scalars['String']['input'];
};


export type MutationLockDrawingArgs = {
  confidential: Scalars['Boolean']['input'];
  uuid: Scalars['Uuid']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  two_factor_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationManageUserAccessArgs = {
  access: Array<UserAccessInput>;
  uuid: Scalars['Uuid']['input'];
};


export type MutationPublishDrawingArgs = {
  published: Scalars['Boolean']['input'];
  uuid: Scalars['Uuid']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['Email']['input'];
};


export type MutationRequestUserPasswordResetArgs = {
  email: Scalars['Email']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRetryProcessingDrawingArgs = {
  drawing_version: Scalars['Uuid']['input'];
};


export type MutationSubmitChangeRequestArgs = {
  drawing_version_uuid: Scalars['Uuid']['input'];
};


export type MutationSubmitFormArgs = {
  attachments?: InputMaybe<Array<FormFieldAttachment>>;
  form_data: Scalars['String']['input'];
  form_id: Scalars['ID']['input'];
  g_recaptcha_response?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateDrawingArgs = {
  confidential: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  discipline: Scalars['ID']['input'];
  floor: Scalars['ID']['input'];
  location: Scalars['Uuid']['input'];
  published: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
  type: Scalars['ID']['input'];
  uuid: Scalars['Uuid']['input'];
};


export type MutationUpdateLocationArgs = {
  address: AddressInput;
  location: Scalars['Uuid']['input'];
  published: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateMarkerStatusArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};


export type MutationUpdateOrganizationArgs = {
  address: AddressInput;
  title: Scalars['String']['input'];
  uuid: Scalars['Uuid']['input'];
};


export type MutationUpdateProjectArgs = {
  project_uuid: Scalars['Uuid']['input'];
  published: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateUserArgs = {
  can_export_drawings: Scalars['Boolean']['input'];
  email: Scalars['Email']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
  published: Scalars['Boolean']['input'];
  role: UserRole;
  uuid: Scalars['Uuid']['input'];
};


export type MutationUpdateUserRoleArgs = {
  role?: InputMaybe<UserRole>;
  uuid: Scalars['Uuid']['input'];
};


export type MutationUploadNewDrawingVersionArgs = {
  drawing: Scalars['Uuid']['input'];
  file: Scalars['Upload']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  address?: Maybe<Address>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  license?: Maybe<Scalars['String']['output']>;
  projects: Array<Project>;
  projects_count?: Maybe<Scalars['Int']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  users: Array<User_A129964a8042821a94e11cbc1c44f586>;
  users_count: Scalars['Int']['output'];
  uuid: Scalars['Uuid']['output'];
};

export type Organization_F8b7064e9f68e6ff9eba78f529ec57dc = {
  __typename?: 'Organization_f8b7064e9f68e6ff9eba78f529ec57dc';
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};

export type Page = {
  __typename?: 'Page';
  active_image?: Maybe<Scalars['Image']['output']>;
  children?: Maybe<Array<Maybe<Page>>>;
  images: Array<Scalars['Image']['output']>;
  parent?: Maybe<Page>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  template?: Maybe<Template>;
  template_name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Project = {
  __typename?: 'Project';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  locations: Array<Location>;
  locations_count?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  users_count: Scalars['Int']['output'];
  uuid: Scalars['Uuid']['output'];
};

export type Query = {
  __typename?: 'Query';
  activityLog: Array<ActivityLog>;
  activityLogForDrawing: Array<ActivityLog>;
  bulkDrawingExport?: Maybe<Scalars['String']['output']>;
  bulkQrCodes?: Maybe<Scalars['String']['output']>;
  categories: Array<Category>;
  currentTenantInfo?: Maybe<Tenant_3af8041b6556776eda1312e7c8acaed7>;
  disciplines: Array<Discipline>;
  drawing?: Maybe<Drawing>;
  drawingAccessToken: Scalars['String']['output'];
  drawingVersion?: Maybe<DrawingVersion>;
  drawings: Array<Drawing>;
  floors: Array<Floor>;
  form?: Maybe<Form>;
  location?: Maybe<Location>;
  locations: Array<Location>;
  me?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  menu?: Maybe<Menu>;
  menus: Array<Menu>;
  organization?: Maybe<Organization>;
  organizations: Array<Organization>;
  page?: Maybe<Page>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  settings: Array<Setting>;
  types: Array<Type>;
  user: User_22881be7471d45df81e4081199322c52;
  userAccess: Array<EntityAccess>;
  userAccessForDrawing?: Maybe<Array<Maybe<User_A129964a8042821a94e11cbc1c44f586>>>;
  users: Array<User_22881be7471d45df81e4081199322c52>;
};


export type QueryActivityLogArgs = {
  filters?: InputMaybe<ActivityFilters>;
};


export type QueryActivityLogForDrawingArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type QueryBulkDrawingExportArgs = {
  uuids: Array<Scalars['Uuid']['input']>;
};


export type QueryBulkQrCodesArgs = {
  uuids: Array<Scalars['Uuid']['input']>;
};


export type QueryCategoriesArgs = {
  hierarchy?: InputMaybe<Scalars['Boolean']['input']>;
  resource_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDisciplinesArgs = {
  location_uuid?: InputMaybe<Scalars['Uuid']['input']>;
};


export type QueryDrawingArgs = {
  access_token?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['Uuid']['input'];
};


export type QueryDrawingAccessTokenArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type QueryDrawingVersionArgs = {
  access_token?: InputMaybe<Scalars['String']['input']>;
  drawing_uuid: Scalars['Uuid']['input'];
  version_uuid?: InputMaybe<Scalars['Uuid']['input']>;
};


export type QueryDrawingsArgs = {
  filters?: InputMaybe<DrawingFilters>;
  location: Scalars['Uuid']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFloorsArgs = {
  location_uuid?: InputMaybe<Scalars['Uuid']['input']>;
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLocationArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type QueryLocationsArgs = {
  project: Scalars['Uuid']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMenuArgs = {
  name: Scalars['String']['input'];
};


export type QueryMenusArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryOrganizationArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type QueryOrganizationsArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPageArgs = {
  segments?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProjectArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type QueryProjectsArgs = {
  organization?: InputMaybe<Scalars['Uuid']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTypesArgs = {
  location_uuid?: InputMaybe<Scalars['Uuid']['input']>;
};


export type QueryUserArgs = {
  uuid: Scalars['Uuid']['input'];
};


export type QueryUserAccessArgs = {
  user: Scalars['Uuid']['input'];
};


export type QueryUserAccessForDrawingArgs = {
  drawing: Scalars['Uuid']['input'];
};


export type QueryUsersArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Seo = {
  __typename?: 'Seo';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_follow?: Maybe<Scalars['Boolean']['output']>;
  meta_index?: Maybe<Scalars['Boolean']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type Setting = {
  __typename?: 'Setting';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Mixed']['output']>;
};

export type Stap = {
  __typename?: 'Stap';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['Image']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Template = TemplateLandingspagina | TemplateStandaard;

export type TemplateLandingspagina = {
  __typename?: 'TemplateLandingspagina';
  capabilities: Array<Capability>;
  costs_package_price?: Maybe<Scalars['Float']['output']>;
  costs_package_price_per?: Maybe<Scalars['String']['output']>;
  costs_package_title?: Maybe<Scalars['String']['output']>;
  costs_package_usps?: Maybe<Scalars['String']['output']>;
  costs_text?: Maybe<Scalars['String']['output']>;
  costs_title?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  demo_form?: Maybe<Form>;
  demo_text?: Maybe<Scalars['String']['output']>;
  demo_title?: Maybe<Scalars['String']['output']>;
  examples: Array<Voorbeeld>;
  examples_count?: Maybe<Scalars['Int']['output']>;
  hero_image?: Maybe<Scalars['Image']['output']>;
  hero_text?: Maybe<Scalars['String']['output']>;
  hero_title?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  steps: Array<Stap>;
  steps_count?: Maybe<Scalars['Int']['output']>;
  text_image_image?: Maybe<Scalars['Image']['output']>;
  text_image_text?: Maybe<Scalars['String']['output']>;
  text_image_title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateStandaard = {
  __typename?: 'TemplateStandaard';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  tekst?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  domain: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  locales: Array<Scalars['String']['output']>;
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Tenant_3af8041b6556776eda1312e7c8acaed7 = {
  __typename?: 'Tenant_3af8041b6556776eda1312e7c8acaed7';
  active_image?: Maybe<Scalars['Image']['output']>;
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
};

export type TwoFactorSecret = {
  __typename?: 'TwoFactorSecret';
  app: Scalars['String']['output'];
  email: Scalars['Email']['output'];
  secret: Scalars['String']['output'];
};

export type Type = {
  __typename?: 'Type';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type User = {
  __typename?: 'User';
  _?: Maybe<Scalars['String']['output']>;
};

export type UserAccessInput = {
  entity_type: Scalars['String']['input'];
  entity_uuid: Scalars['Uuid']['input'];
  type: Scalars['String']['input'];
};

export enum UserRole {
  Contributor = 'Contributor',
  Developer = 'Developer',
  Editor = 'Editor',
  Manager = 'Manager',
  OrganizationManager = 'OrganizationManager',
  ReadOnly = 'ReadOnly'
}

export type User_07e75d37dfdb1fad1b23e74888c041b4 = {
  __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4';
  active_image?: Maybe<Scalars['Image']['output']>;
  avatar: Scalars['String']['output'];
  /** Let op! Wanneer je dit aanzet kunnen QR-codes voor alle tekeningen van projecten van deze gebruiker alleen geopend worden door ingelogde gebruikers. */
  can_export_drawings?: Maybe<Scalars['Boolean']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  first_name: Scalars['String']['output'];
  images: Array<Scalars['Image']['output']>;
  initials: Scalars['String']['output'];
  last_login?: Maybe<Scalars['DateTime']['output']>;
  last_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  phone_number?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  role?: Maybe<UserRole>;
  roles: Array<UserRole>;
  tenants: Array<Tenant>;
  tenants_count?: Maybe<Scalars['Int']['output']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['Uuid']['output'];
};


export type User_07e75d37dfdb1fad1b23e74888c041b4AvatarArgs = {
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type User_22881be7471d45df81e4081199322c52 = {
  __typename?: 'User_22881be7471d45df81e4081199322c52';
  /** Let op! Wanneer je dit aanzet kunnen QR-codes voor alle tekeningen van projecten van deze gebruiker alleen geopend worden door ingelogde gebruikers. */
  can_export_drawings?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['Email']['output'];
  first_name: Scalars['String']['output'];
  last_login?: Maybe<Scalars['DateTime']['output']>;
  last_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization?: Maybe<Organization_F8b7064e9f68e6ff9eba78f529ec57dc>;
  phone_number?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  role?: Maybe<UserRole>;
  two_factor_enabled?: Maybe<Scalars['Boolean']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type User_A129964a8042821a94e11cbc1c44f586 = {
  __typename?: 'User_a129964a8042821a94e11cbc1c44f586';
  email: Scalars['Email']['output'];
  name: Scalars['String']['output'];
  role?: Maybe<UserRole>;
  uuid: Scalars['Uuid']['output'];
};

export type User_Bc2e2ba7cc62f36b5c95747d164af31c = {
  __typename?: 'User_bc2e2ba7cc62f36b5c95747d164af31c';
  name: Scalars['String']['output'];
  uuid: Scalars['Uuid']['output'];
};

export type Voorbeeld = {
  __typename?: 'Voorbeeld';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type CreateDrawingMutationVariables = Exact<{
  title: Scalars['String']['input'];
  type: Scalars['ID']['input'];
  discipline: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  location: Scalars['Uuid']['input'];
  floor: Scalars['ID']['input'];
  published: Scalars['Boolean']['input'];
  confidential: Scalars['Boolean']['input'];
}>;


export type CreateDrawingMutation = { __typename?: 'Mutation', createDrawing: { __typename?: 'Drawing', uuid: string, title: string, description?: string | null, published: boolean, confidential?: boolean | null } };

export type DeleteDrawingMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type DeleteDrawingMutation = { __typename?: 'Mutation', deleteDrawing?: null | null };

export type LockDrawingMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  confidential: Scalars['Boolean']['input'];
}>;


export type LockDrawingMutation = { __typename?: 'Mutation', lockDrawing: boolean };

export type PublishDrawingMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  published: Scalars['Boolean']['input'];
}>;


export type PublishDrawingMutation = { __typename?: 'Mutation', publishDrawing: boolean };

export type RetryProcessingDrawingMutationVariables = Exact<{
  drawing_version: Scalars['Uuid']['input'];
}>;


export type RetryProcessingDrawingMutation = { __typename?: 'Mutation', retryProcessingDrawing?: null | null };

export type SubmitChangeRequestMutationVariables = Exact<{
  drawing_version_uuid: Scalars['Uuid']['input'];
}>;


export type SubmitChangeRequestMutation = { __typename?: 'Mutation', submitChangeRequest: { __typename?: 'ChangeRequest', status?: string | null } };

export type UpdateDrawingMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  published: Scalars['Boolean']['input'];
  confidential: Scalars['Boolean']['input'];
  type: Scalars['ID']['input'];
  discipline: Scalars['ID']['input'];
  floor: Scalars['ID']['input'];
  location: Scalars['Uuid']['input'];
}>;


export type UpdateDrawingMutation = { __typename?: 'Mutation', updateDrawing: { __typename?: 'Drawing', uuid: string, title: string, description?: string | null, published: boolean, confidential?: boolean | null, discipline: { __typename?: 'Discipline', id: number, title: string }, floor: { __typename?: 'Floor', id: number, title: string }, type: { __typename?: 'Type', id: number, title: string }, locations: Array<{ __typename?: 'Location', uuid: string, title: string }> } };

export type UploadNewDrawingVersionMutationVariables = Exact<{
  drawing: Scalars['Uuid']['input'];
  file: Scalars['Upload']['input'];
}>;


export type UploadNewDrawingVersionMutation = { __typename?: 'Mutation', uploadNewDrawingVersion?: null | null };

export type CreateLocationMutationVariables = Exact<{
  title: Scalars['String']['input'];
  address: AddressInput;
  project: Scalars['Uuid']['input'];
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation?: null | null };

export type DeleteLocationMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type DeleteLocationMutation = { __typename?: 'Mutation', deleteLocation?: null | null };

export type UpdateLocationMutationVariables = Exact<{
  location: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
  published: Scalars['Boolean']['input'];
  address: AddressInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'Location', uuid: string, title: string, published: boolean, address?: { __typename?: 'Address', id: number, country?: string | null, city?: string | null, street: string, zipcode?: string | null, house_number?: string | null, house_number_suffix?: string | null } | null } };

export type CreateMarkerMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  type: MarkerType;
  symbol?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  linked_drawing?: InputMaybe<Scalars['Uuid']['input']>;
  coordinates: Array<Scalars['String']['input']> | Scalars['String']['input'];
  pageIndex: Scalars['Int']['input'];
}>;


export type CreateMarkerMutation = { __typename?: 'Mutation', createMarker: { __typename?: 'Marker', id: number, title: string, type: MarkerType } };

export type DeleteMarkerMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteMarkerMutation = { __typename?: 'Mutation', deleteMarker?: null | null };

export type UpdateMarkerStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: Scalars['String']['input'];
}>;


export type UpdateMarkerStatusMutation = { __typename?: 'Mutation', updateMarkerStatus: { __typename?: 'Marker', status?: string | null } };

export type CreateOrganizationMutationVariables = Exact<{
  title: Scalars['String']['input'];
  address: AddressInput;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization?: null | null };

export type DeleteOrganizationMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type DeleteOrganizationMutation = { __typename?: 'Mutation', deleteOrganization?: null | null };

export type UpdateOrganizationMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
  address: AddressInput;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization?: null | null };

export type CreateProjectMutationVariables = Exact<{
  organization_uuid: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject?: null | null };

export type DeleteProjectMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type DeleteProjectMutation = { __typename?: 'Mutation', deleteProject?: null | null };

export type UpdateProjectMutationVariables = Exact<{
  project_uuid: Scalars['Uuid']['input'];
  title: Scalars['String']['input'];
  published: Scalars['Boolean']['input'];
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject: { __typename?: 'Project', uuid: string, title: string, published: boolean } };

export type CreateUserMutationVariables = Exact<{
  organization?: InputMaybe<Scalars['Uuid']['input']>;
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
  role: UserRole;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User_22881be7471d45df81e4081199322c52', uuid: string, name: string, first_name: string, last_name: string, email: string, phone_number?: string | null, role?: UserRole | null, published: boolean, two_factor_enabled?: boolean | null, organization?: { __typename?: 'Organization_f8b7064e9f68e6ff9eba78f529ec57dc', uuid: string, title: string } | null } };

export type DeleteUserMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: null | null };

export type ManageUserAccessMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  access: Array<UserAccessInput> | UserAccessInput;
}>;


export type ManageUserAccessMutation = { __typename?: 'Mutation', manageUserAccess?: null | null };

export type RequestUserPasswordResetMutationVariables = Exact<{
  email: Scalars['Email']['input'];
}>;


export type RequestUserPasswordResetMutation = { __typename?: 'Mutation', requestUserPasswordReset?: null | null };

export type UpdateUserMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
  role: UserRole;
  published: Scalars['Boolean']['input'];
  can_export_drawings: Scalars['Boolean']['input'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User_22881be7471d45df81e4081199322c52', uuid: string, name: string, first_name: string, last_name: string, email: string, phone_number?: string | null, role?: UserRole | null, published: boolean } };

export type UpdateUserRoleMutationVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  role: UserRole;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole?: null | null };

export type ActivityLogQueryVariables = Exact<{
  filters?: InputMaybe<ActivityFilters>;
}>;


export type ActivityLogQuery = { __typename?: 'Query', activityLog: Array<{ __typename?: 'ActivityLog', uuid: string, type: ActivityType, created_at: string, user?: { __typename?: 'User_bc2e2ba7cc62f36b5c95747d164af31c', uuid: string, name: string } | null, project?: { __typename?: 'Project', uuid: string, title: string } | null, organization?: { __typename?: 'Organization', uuid: string, title: string } | null, location?: { __typename?: 'Location', uuid: string, title: string } | null, drawing?: { __typename?: 'Drawing', uuid: string, title: string } | null }> };

export type ActivityLogFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type ActivityLogFiltersQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', uuid: string, title: string }>, projects: Array<{ __typename?: 'Project', uuid: string, title: string }>, users: Array<{ __typename?: 'User_22881be7471d45df81e4081199322c52', uuid: string, name: string }> };

export type ActivityLogForDrawingQueryVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type ActivityLogForDrawingQuery = { __typename?: 'Query', activityLogForDrawing: Array<{ __typename?: 'ActivityLog', uuid: string, type: ActivityType, created_at: string, user?: { __typename?: 'User_bc2e2ba7cc62f36b5c95747d164af31c', uuid: string, name: string } | null }> };

export type MemberActivityLogFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type MemberActivityLogFiltersQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', uuid: string, title: string }>, projects: Array<{ __typename?: 'Project', uuid: string, title: string }> };

export type BulkDrawingExportQueryVariables = Exact<{
  uuids: Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input'];
}>;


export type BulkDrawingExportQuery = { __typename?: 'Query', bulkDrawingExport?: string | null };

export type BulkQrCodesQueryVariables = Exact<{
  uuids: Array<Scalars['Uuid']['input']> | Scalars['Uuid']['input'];
}>;


export type BulkQrCodesQuery = { __typename?: 'Query', bulkQrCodes?: string | null };

export type DrawingQueryVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
  access_token?: InputMaybe<Scalars['String']['input']>;
}>;


export type DrawingQuery = { __typename?: 'Query', drawing?: { __typename?: 'Drawing', uuid: string, title: string, description?: string | null, published: boolean, confidential?: boolean | null, discipline: { __typename?: 'Discipline', id: number, title: string }, floor: { __typename?: 'Floor', id: number, title: string }, type: { __typename?: 'Type', id: number, title: string }, locations: Array<{ __typename?: 'Location', uuid: string, published: boolean, title: string }>, location: { __typename?: 'Location', uuid: string, published: boolean, title: string, drawings: Array<{ __typename?: 'Drawing', uuid: string, title: string, discipline: { __typename?: 'Discipline', id: number, title: string }, floor: { __typename?: 'Floor', id: number, title: string }, type: { __typename?: 'Type', id: number, title: string } }> }, project: { __typename?: 'Project', uuid: string, published: boolean, title: string, organization: { __typename?: 'Organization', uuid: string, title: string } }, latest_version?: { __typename?: 'DrawingVersion', uuid: string } | null, versions: Array<{ __typename?: 'DrawingVersion', uuid: string, title: string }> } | null };

export type DrawingAccessTokenQueryVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type DrawingAccessTokenQuery = { __typename?: 'Query', drawingAccessToken: string };

export type DrawingVersionQueryVariables = Exact<{
  drawingUuid: Scalars['Uuid']['input'];
  versionUuid?: InputMaybe<Scalars['Uuid']['input']>;
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type DrawingVersionQuery = { __typename?: 'Query', drawingVersion?: { __typename?: 'DrawingVersion', uuid: string, drawing: Array<any>, status?: string | null, change_requests: Array<{ __typename?: 'ChangeRequest', is_sent: boolean, suggestions: Array<{ __typename?: 'Marker', id: number, title: string, x: number, y: number, created_at?: string | null, comment?: string | null, symbol?: Image | null, images: Array<Image>, type: MarkerType, status?: string | null, is_sent: boolean, page_index?: number | null, reporter?: { __typename?: 'User_bc2e2ba7cc62f36b5c95747d164af31c', uuid: string, name: string } | null }>, links: Array<{ __typename?: 'Marker', id: number, title: string, x: number, y: number, created_at?: string | null, type: MarkerType, page_index?: number | null, reporter?: { __typename?: 'User_bc2e2ba7cc62f36b5c95747d164af31c', uuid: string, name: string } | null, drawing?: { __typename?: 'Drawing', uuid: string, title: string, version?: string | null } | null }> }> } | null };

export type DrawingsQueryVariables = Exact<{
  location: Scalars['Uuid']['input'];
  filters?: InputMaybe<DrawingFilters>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;


export type DrawingsQuery = { __typename?: 'Query', drawings: Array<{ __typename?: 'Drawing', uuid: string, title: string, description?: string | null, updated_at?: string | null, published: boolean, confidential?: boolean | null, discipline: { __typename?: 'Discipline', id: number, title: string }, floor: { __typename?: 'Floor', id: number, title: string }, type: { __typename?: 'Type', id: number, title: string }, latest_version?: { __typename?: 'DrawingVersion', title: string, change_requests: Array<{ __typename?: 'ChangeRequest', status?: string | null, suggestions: Array<{ __typename?: 'Marker', id: number }> }> } | null }> };

export type UserAccessForDrawingQueryQueryVariables = Exact<{
  drawing: Scalars['Uuid']['input'];
}>;


export type UserAccessForDrawingQueryQuery = { __typename?: 'Query', userAccessForDrawing?: Array<{ __typename?: 'User_a129964a8042821a94e11cbc1c44f586', uuid: string, name: string, email: string, role?: UserRole | null } | null> | null };

export type LocationQueryVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type LocationQuery = { __typename?: 'Query', location?: { __typename?: 'Location', uuid: string, title: string, published: boolean, drawings: Array<{ __typename?: 'Drawing', uuid: string, title: string, updated_at?: string | null, discipline: { __typename?: 'Discipline', id: number, title: string }, floor: { __typename?: 'Floor', id: number, title: string }, type: { __typename?: 'Type', id: number, title: string }, latest_version?: { __typename?: 'DrawingVersion', version: string, change_requests: Array<{ __typename?: 'ChangeRequest', suggestions_count?: number | null }> } | null }>, address?: { __typename?: 'Address', street: string, city?: string | null, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null } | null, projects: Array<{ __typename?: 'Project', uuid: string, organization: { __typename?: 'Organization', uuid: string, title: string, users_count: number } }> } | null };

export type LocationsQueryVariables = Exact<{
  project: Scalars['Uuid']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type LocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', uuid: string, title: string, total_suggestions_count: number, drawings_count?: number | null, updated_at?: string | null, published: boolean, address?: { __typename?: 'Address', address_string?: string | null, street: string, city?: string | null, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null } | null }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4', uuid: string, email: string, name: string, two_factor_enabled?: boolean | null, role?: UserRole | null, can_export_drawings?: boolean | null, organization?: { __typename?: 'Organization', uuid: string, title: string } | null } | null };

export type OrganizationQueryVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type OrganizationQuery = { __typename?: 'Query', organization?: { __typename?: 'Organization', uuid: string, title: string, address?: { __typename?: 'Address', city?: string | null, country?: string | null, house_number?: string | null, house_number_suffix?: string | null, latitude?: number | null, longitude?: number | null, street: string, zipcode?: string | null } | null, users: Array<{ __typename?: 'User_a129964a8042821a94e11cbc1c44f586', uuid: string, name: string, email: string, role?: UserRole | null }> } | null };

export type OrganizationsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;


export type OrganizationsQuery = { __typename?: 'Query', organizations: Array<{ __typename?: 'Organization', uuid: string, title: string, users_count: number, address?: { __typename?: 'Address', address_string?: string | null } | null }> };

export type DisciplinesQueryVariables = Exact<{
  locationUuid?: InputMaybe<Scalars['Uuid']['input']>;
}>;


export type DisciplinesQuery = { __typename?: 'Query', disciplines: Array<{ __typename?: 'Discipline', id: number, title: string }> };

export type FloorsQueryVariables = Exact<{
  locationUuid?: InputMaybe<Scalars['Uuid']['input']>;
}>;


export type FloorsQuery = { __typename?: 'Query', floors: Array<{ __typename?: 'Floor', id: number, title: string }> };

export type TypesQueryVariables = Exact<{
  locationUuid?: InputMaybe<Scalars['Uuid']['input']>;
}>;


export type TypesQuery = { __typename?: 'Query', types: Array<{ __typename?: 'Type', id: number, title: string }> };

export type ProjectQueryVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type ProjectQuery = { __typename?: 'Query', project?: { __typename?: 'Project', uuid: string, title: string, published: boolean, organization: { __typename?: 'Organization', uuid: string, title: string, users_count: number } } | null };

export type ProjectsQueryVariables = Exact<{
  organization?: InputMaybe<Scalars['Uuid']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', uuid: string, title: string, published: boolean, locations_count?: number | null, users_count: number, organization: { __typename?: 'Organization', uuid: string, title: string }, locations: Array<{ __typename?: 'Location', uuid: string, title: string, published: boolean, drawings: Array<{ __typename?: 'Drawing', uuid: string, title: string }> }> }> };

export type PageQueryVariables = Exact<{
  segments: Scalars['String']['input'];
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', active_image?: Image | null, slug: string, title: string, url: string, seo?: { __typename?: 'Seo', meta_title?: string | null, meta_description?: string | null, meta_index?: boolean | null, meta_follow?: boolean | null } | null, template?: { __typename: 'TemplateLandingspagina', hero_title?: string | null, hero_text?: string | null, hero_image?: Image | null, text_image_title?: string | null, text_image_text?: string | null, text_image_image?: Image | null, costs_title?: string | null, costs_text?: string | null, costs_package_price?: number | null, costs_package_price_per?: string | null, costs_package_title?: string | null, costs_package_usps?: string | null, demo_title?: string | null, demo_text?: string | null, examples: Array<{ __typename?: 'Voorbeeld', title: string, text?: string | null }>, steps: Array<{ __typename?: 'Stap', title: string, text?: string | null, image?: Image | null }>, demo_form?: { __typename?: 'Form', id: number } | null } | { __typename: 'TemplateStandaard' } | null } | null };

export type UserQueryVariables = Exact<{
  uuid: Scalars['Uuid']['input'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User_22881be7471d45df81e4081199322c52', uuid: string, email: string, phone_number?: string | null, name: string, two_factor_enabled?: boolean | null, first_name: string, last_name: string, published: boolean, role?: UserRole | null, last_login?: string | null, can_export_drawings?: boolean | null, organization?: { __typename?: 'Organization_f8b7064e9f68e6ff9eba78f529ec57dc', uuid: string, title: string } | null } };

export type UsersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User_22881be7471d45df81e4081199322c52', uuid: string, email: string, phone_number?: string | null, name: string, role?: UserRole | null, organization?: { __typename?: 'Organization_f8b7064e9f68e6ff9eba78f529ec57dc', uuid: string, title: string } | null }> };

export type UserAccessQueryVariables = Exact<{
  user: Scalars['Uuid']['input'];
}>;


export type UserAccessQuery = { __typename?: 'Query', userAccess: Array<{ __typename?: 'EntityAccess', uuid: string, title: string, accessible: boolean, locations?: Array<{ __typename?: 'EntityAccess', uuid: string, title: string, accessible: boolean, drawings?: Array<{ __typename?: 'EntityAccess', uuid: string, title: string, accessible: boolean }> | null }> | null }> };


export const CreateDrawingDocument = gql`
    mutation createDrawing($title: String!, $type: ID!, $discipline: ID!, $description: String, $file: Upload, $location: Uuid!, $floor: ID!, $published: Boolean!, $confidential: Boolean!) {
  createDrawing(
    title: $title
    type: $type
    discipline: $discipline
    description: $description
    file: $file
    location: $location
    floor: $floor
    published: $published
    confidential: $confidential
  ) {
    uuid
    title
    description
    published
    confidential
  }
}
    `;

/**
 * __useCreateDrawingMutation__
 *
 * To run a mutation, you first call `useCreateDrawingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateDrawingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateDrawingMutation({
 *   variables: {
 *     title: // value for 'title'
 *     type: // value for 'type'
 *     discipline: // value for 'discipline'
 *     description: // value for 'description'
 *     file: // value for 'file'
 *     location: // value for 'location'
 *     floor: // value for 'floor'
 *     published: // value for 'published'
 *     confidential: // value for 'confidential'
 *   },
 * });
 */
export function useCreateDrawingMutation(options: VueApolloComposable.UseMutationOptions<CreateDrawingMutation, CreateDrawingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateDrawingMutation, CreateDrawingMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateDrawingMutation, CreateDrawingMutationVariables>(CreateDrawingDocument, options);
}
export type CreateDrawingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateDrawingMutation, CreateDrawingMutationVariables>;
export const DeleteDrawingDocument = gql`
    mutation deleteDrawing($uuid: Uuid!) {
  deleteDrawing(uuid: $uuid)
}
    `;

/**
 * __useDeleteDrawingMutation__
 *
 * To run a mutation, you first call `useDeleteDrawingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDrawingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDrawingMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteDrawingMutation(options: VueApolloComposable.UseMutationOptions<DeleteDrawingMutation, DeleteDrawingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteDrawingMutation, DeleteDrawingMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteDrawingMutation, DeleteDrawingMutationVariables>(DeleteDrawingDocument, options);
}
export type DeleteDrawingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteDrawingMutation, DeleteDrawingMutationVariables>;
export const LockDrawingDocument = gql`
    mutation lockDrawing($uuid: Uuid!, $confidential: Boolean!) {
  lockDrawing(uuid: $uuid, confidential: $confidential)
}
    `;

/**
 * __useLockDrawingMutation__
 *
 * To run a mutation, you first call `useLockDrawingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useLockDrawingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useLockDrawingMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     confidential: // value for 'confidential'
 *   },
 * });
 */
export function useLockDrawingMutation(options: VueApolloComposable.UseMutationOptions<LockDrawingMutation, LockDrawingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<LockDrawingMutation, LockDrawingMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<LockDrawingMutation, LockDrawingMutationVariables>(LockDrawingDocument, options);
}
export type LockDrawingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<LockDrawingMutation, LockDrawingMutationVariables>;
export const PublishDrawingDocument = gql`
    mutation publishDrawing($uuid: Uuid!, $published: Boolean!) {
  publishDrawing(uuid: $uuid, published: $published)
}
    `;

/**
 * __usePublishDrawingMutation__
 *
 * To run a mutation, you first call `usePublishDrawingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `usePublishDrawingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = usePublishDrawingMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     published: // value for 'published'
 *   },
 * });
 */
export function usePublishDrawingMutation(options: VueApolloComposable.UseMutationOptions<PublishDrawingMutation, PublishDrawingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<PublishDrawingMutation, PublishDrawingMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<PublishDrawingMutation, PublishDrawingMutationVariables>(PublishDrawingDocument, options);
}
export type PublishDrawingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<PublishDrawingMutation, PublishDrawingMutationVariables>;
export const RetryProcessingDrawingDocument = gql`
    mutation retryProcessingDrawing($drawing_version: Uuid!) {
  retryProcessingDrawing(drawing_version: $drawing_version)
}
    `;

/**
 * __useRetryProcessingDrawingMutation__
 *
 * To run a mutation, you first call `useRetryProcessingDrawingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRetryProcessingDrawingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRetryProcessingDrawingMutation({
 *   variables: {
 *     drawing_version: // value for 'drawing_version'
 *   },
 * });
 */
export function useRetryProcessingDrawingMutation(options: VueApolloComposable.UseMutationOptions<RetryProcessingDrawingMutation, RetryProcessingDrawingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RetryProcessingDrawingMutation, RetryProcessingDrawingMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RetryProcessingDrawingMutation, RetryProcessingDrawingMutationVariables>(RetryProcessingDrawingDocument, options);
}
export type RetryProcessingDrawingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RetryProcessingDrawingMutation, RetryProcessingDrawingMutationVariables>;
export const SubmitChangeRequestDocument = gql`
    mutation submitChangeRequest($drawing_version_uuid: Uuid!) {
  submitChangeRequest(drawing_version_uuid: $drawing_version_uuid) {
    status
  }
}
    `;

/**
 * __useSubmitChangeRequestMutation__
 *
 * To run a mutation, you first call `useSubmitChangeRequestMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitChangeRequestMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitChangeRequestMutation({
 *   variables: {
 *     drawing_version_uuid: // value for 'drawing_version_uuid'
 *   },
 * });
 */
export function useSubmitChangeRequestMutation(options: VueApolloComposable.UseMutationOptions<SubmitChangeRequestMutation, SubmitChangeRequestMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitChangeRequestMutation, SubmitChangeRequestMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitChangeRequestMutation, SubmitChangeRequestMutationVariables>(SubmitChangeRequestDocument, options);
}
export type SubmitChangeRequestMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitChangeRequestMutation, SubmitChangeRequestMutationVariables>;
export const UpdateDrawingDocument = gql`
    mutation updateDrawing($uuid: Uuid!, $title: String!, $description: String, $published: Boolean!, $confidential: Boolean!, $type: ID!, $discipline: ID!, $floor: ID!, $location: Uuid!) {
  updateDrawing(
    uuid: $uuid
    title: $title
    description: $description
    published: $published
    confidential: $confidential
    discipline: $discipline
    floor: $floor
    type: $type
    location: $location
  ) {
    uuid
    title
    description
    published
    confidential
    discipline {
      id
      title
    }
    floor {
      id
      title
    }
    type {
      id
      title
    }
    locations {
      uuid
      title
    }
  }
}
    `;

/**
 * __useUpdateDrawingMutation__
 *
 * To run a mutation, you first call `useUpdateDrawingMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDrawingMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateDrawingMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     title: // value for 'title'
 *     description: // value for 'description'
 *     published: // value for 'published'
 *     confidential: // value for 'confidential'
 *     type: // value for 'type'
 *     discipline: // value for 'discipline'
 *     floor: // value for 'floor'
 *     location: // value for 'location'
 *   },
 * });
 */
export function useUpdateDrawingMutation(options: VueApolloComposable.UseMutationOptions<UpdateDrawingMutation, UpdateDrawingMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateDrawingMutation, UpdateDrawingMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateDrawingMutation, UpdateDrawingMutationVariables>(UpdateDrawingDocument, options);
}
export type UpdateDrawingMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateDrawingMutation, UpdateDrawingMutationVariables>;
export const UploadNewDrawingVersionDocument = gql`
    mutation uploadNewDrawingVersion($drawing: Uuid!, $file: Upload!) {
  uploadNewDrawingVersion(drawing: $drawing, file: $file)
}
    `;

/**
 * __useUploadNewDrawingVersionMutation__
 *
 * To run a mutation, you first call `useUploadNewDrawingVersionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUploadNewDrawingVersionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUploadNewDrawingVersionMutation({
 *   variables: {
 *     drawing: // value for 'drawing'
 *     file: // value for 'file'
 *   },
 * });
 */
export function useUploadNewDrawingVersionMutation(options: VueApolloComposable.UseMutationOptions<UploadNewDrawingVersionMutation, UploadNewDrawingVersionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UploadNewDrawingVersionMutation, UploadNewDrawingVersionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UploadNewDrawingVersionMutation, UploadNewDrawingVersionMutationVariables>(UploadNewDrawingVersionDocument, options);
}
export type UploadNewDrawingVersionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UploadNewDrawingVersionMutation, UploadNewDrawingVersionMutationVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($title: String!, $address: AddressInput!, $project: Uuid!) {
  createLocation(title: $title, address: $address, project: $project)
}
    `;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateLocationMutation({
 *   variables: {
 *     title: // value for 'title'
 *     address: // value for 'address'
 *     project: // value for 'project'
 *   },
 * });
 */
export function useCreateLocationMutation(options: VueApolloComposable.UseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
}
export type CreateLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateLocationMutation, CreateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation deleteLocation($uuid: Uuid!) {
  deleteLocation(uuid: $uuid)
}
    `;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteLocationMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteLocationMutation(options: VueApolloComposable.UseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
}
export type DeleteLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($location: Uuid!, $title: String!, $published: Boolean!, $address: AddressInput!) {
  updateLocation(
    location: $location
    title: $title
    published: $published
    address: $address
  ) {
    uuid
    title
    published
    address {
      id
      country
      city
      street
      zipcode
      house_number
      house_number_suffix
    }
  }
}
    `;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateLocationMutation({
 *   variables: {
 *     location: // value for 'location'
 *     title: // value for 'title'
 *     published: // value for 'published'
 *     address: // value for 'address'
 *   },
 * });
 */
export function useUpdateLocationMutation(options: VueApolloComposable.UseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
}
export type UpdateLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const CreateMarkerDocument = gql`
    mutation createMarker($uuid: Uuid!, $type: MarkerType!, $symbol: String, $images: [Upload!], $comment: String, $linked_drawing: Uuid, $coordinates: [String!]!, $pageIndex: Int!) {
  createMarker(
    uuid: $uuid
    type: $type
    symbol: $symbol
    images: $images
    comment: $comment
    linked_drawing: $linked_drawing
    coordinates: $coordinates
    page_index: $pageIndex
  ) {
    id
    title
    type
  }
}
    `;

/**
 * __useCreateMarkerMutation__
 *
 * To run a mutation, you first call `useCreateMarkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateMarkerMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     type: // value for 'type'
 *     symbol: // value for 'symbol'
 *     images: // value for 'images'
 *     comment: // value for 'comment'
 *     linked_drawing: // value for 'linked_drawing'
 *     coordinates: // value for 'coordinates'
 *     pageIndex: // value for 'pageIndex'
 *   },
 * });
 */
export function useCreateMarkerMutation(options: VueApolloComposable.UseMutationOptions<CreateMarkerMutation, CreateMarkerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateMarkerMutation, CreateMarkerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateMarkerMutation, CreateMarkerMutationVariables>(CreateMarkerDocument, options);
}
export type CreateMarkerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateMarkerMutation, CreateMarkerMutationVariables>;
export const DeleteMarkerDocument = gql`
    mutation deleteMarker($id: ID!) {
  deleteMarker(id: $id)
}
    `;

/**
 * __useDeleteMarkerMutation__
 *
 * To run a mutation, you first call `useDeleteMarkerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMarkerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteMarkerMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMarkerMutation(options: VueApolloComposable.UseMutationOptions<DeleteMarkerMutation, DeleteMarkerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteMarkerMutation, DeleteMarkerMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteMarkerMutation, DeleteMarkerMutationVariables>(DeleteMarkerDocument, options);
}
export type DeleteMarkerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteMarkerMutation, DeleteMarkerMutationVariables>;
export const UpdateMarkerStatusDocument = gql`
    mutation updateMarkerStatus($id: ID!, $status: String!) {
  updateMarkerStatus(id: $id, status: $status) {
    status
  }
}
    `;

/**
 * __useUpdateMarkerStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMarkerStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarkerStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateMarkerStatusMutation({
 *   variables: {
 *     id: // value for 'id'
 *     status: // value for 'status'
 *   },
 * });
 */
export function useUpdateMarkerStatusMutation(options: VueApolloComposable.UseMutationOptions<UpdateMarkerStatusMutation, UpdateMarkerStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateMarkerStatusMutation, UpdateMarkerStatusMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateMarkerStatusMutation, UpdateMarkerStatusMutationVariables>(UpdateMarkerStatusDocument, options);
}
export type UpdateMarkerStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateMarkerStatusMutation, UpdateMarkerStatusMutationVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($title: String!, $address: AddressInput!) {
  createOrganization(title: $title, address: $address)
}
    `;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrganizationMutation({
 *   variables: {
 *     title: // value for 'title'
 *     address: // value for 'address'
 *   },
 * });
 */
export function useCreateOrganizationMutation(options: VueApolloComposable.UseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($uuid: Uuid!) {
  deleteOrganization(uuid: $uuid)
}
    `;

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOrganizationMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(options: VueApolloComposable.UseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, options);
}
export type DeleteOrganizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($uuid: Uuid!, $title: String!, $address: AddressInput!) {
  updateOrganization(uuid: $uuid, title: $title, address: $address)
}
    `;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOrganizationMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     title: // value for 'title'
 *     address: // value for 'address'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(options: VueApolloComposable.UseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
}
export type UpdateOrganizationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($organization_uuid: Uuid!, $title: String!) {
  createProject(organization_uuid: $organization_uuid, title: $title)
}
    `;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateProjectMutation({
 *   variables: {
 *     organization_uuid: // value for 'organization_uuid'
 *     title: // value for 'title'
 *   },
 * });
 */
export function useCreateProjectMutation(options: VueApolloComposable.UseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
}
export type CreateProjectMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateProjectMutation, CreateProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($uuid: Uuid!) {
  deleteProject(uuid: $uuid)
}
    `;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProjectMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteProjectMutation(options: VueApolloComposable.UseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
}
export type DeleteProjectMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($project_uuid: Uuid!, $title: String!, $published: Boolean!) {
  updateProject(project_uuid: $project_uuid, title: $title, published: $published) {
    uuid
    title
    published
  }
}
    `;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProjectMutation({
 *   variables: {
 *     project_uuid: // value for 'project_uuid'
 *     title: // value for 'title'
 *     published: // value for 'published'
 *   },
 * });
 */
export function useUpdateProjectMutation(options: VueApolloComposable.UseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
}
export type UpdateProjectMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($organization: Uuid, $first_name: String!, $last_name: String!, $email: Email!, $phone_number: String, $role: UserRole!) {
  createUser(
    organization: $organization
    first_name: $first_name
    last_name: $last_name
    email: $email
    phone_number: $phone_number
    role: $role
  ) {
    uuid
    name
    first_name
    last_name
    email
    phone_number
    role
    published
    organization {
      uuid
      title
    }
    two_factor_enabled
  }
}
    `;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateUserMutation({
 *   variables: {
 *     organization: // value for 'organization'
 *     first_name: // value for 'first_name'
 *     last_name: // value for 'last_name'
 *     email: // value for 'email'
 *     phone_number: // value for 'phone_number'
 *     role: // value for 'role'
 *   },
 * });
 */
export function useCreateUserMutation(options: VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
}
export type CreateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($uuid: Uuid!) {
  deleteUser(uuid: $uuid)
}
    `;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUserMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteUserMutation(options: VueApolloComposable.UseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteUserMutation, DeleteUserMutationVariables>;
export const ManageUserAccessDocument = gql`
    mutation manageUserAccess($uuid: Uuid!, $access: [UserAccessInput!]!) {
  manageUserAccess(uuid: $uuid, access: $access)
}
    `;

/**
 * __useManageUserAccessMutation__
 *
 * To run a mutation, you first call `useManageUserAccessMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useManageUserAccessMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useManageUserAccessMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     access: // value for 'access'
 *   },
 * });
 */
export function useManageUserAccessMutation(options: VueApolloComposable.UseMutationOptions<ManageUserAccessMutation, ManageUserAccessMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ManageUserAccessMutation, ManageUserAccessMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<ManageUserAccessMutation, ManageUserAccessMutationVariables>(ManageUserAccessDocument, options);
}
export type ManageUserAccessMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ManageUserAccessMutation, ManageUserAccessMutationVariables>;
export const RequestUserPasswordResetDocument = gql`
    mutation requestUserPasswordReset($email: Email!) {
  requestUserPasswordReset(email: $email)
}
    `;

/**
 * __useRequestUserPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestUserPasswordResetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRequestUserPasswordResetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRequestUserPasswordResetMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useRequestUserPasswordResetMutation(options: VueApolloComposable.UseMutationOptions<RequestUserPasswordResetMutation, RequestUserPasswordResetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RequestUserPasswordResetMutation, RequestUserPasswordResetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RequestUserPasswordResetMutation, RequestUserPasswordResetMutationVariables>(RequestUserPasswordResetDocument, options);
}
export type RequestUserPasswordResetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RequestUserPasswordResetMutation, RequestUserPasswordResetMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($uuid: Uuid!, $first_name: String!, $last_name: String!, $email: Email!, $phone_number: String, $role: UserRole!, $published: Boolean!, $can_export_drawings: Boolean!) {
  updateUser(
    uuid: $uuid
    first_name: $first_name
    last_name: $last_name
    email: $email
    phone_number: $phone_number
    role: $role
    published: $published
    can_export_drawings: $can_export_drawings
  ) {
    uuid
    name
    first_name
    last_name
    email
    phone_number
    role
    published
  }
}
    `;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     first_name: // value for 'first_name'
 *     last_name: // value for 'last_name'
 *     email: // value for 'email'
 *     phone_number: // value for 'phone_number'
 *     role: // value for 'role'
 *     published: // value for 'published'
 *     can_export_drawings: // value for 'can_export_drawings'
 *   },
 * });
 */
export function useUpdateUserMutation(options: VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation updateUserRole($uuid: Uuid!, $role: UserRole!) {
  updateUserRole(uuid: $uuid, role: $role)
}
    `;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserRoleMutation({
 *   variables: {
 *     uuid: // value for 'uuid'
 *     role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(options: VueApolloComposable.UseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
}
export type UpdateUserRoleMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const ActivityLogDocument = gql`
    query activityLog($filters: ActivityFilters) {
  activityLog(filters: $filters) {
    uuid
    type
    created_at
    user {
      uuid
      name
    }
    project {
      uuid
      title
    }
    organization {
      uuid
      title
    }
    location {
      uuid
      title
    }
    drawing {
      uuid
      title
    }
  }
}
    `;

/**
 * __useActivityLogQuery__
 *
 * To run a query within a Vue component, call `useActivityLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useActivityLogQuery({
 *   filters: // value for 'filters'
 * });
 */
export function useActivityLogQuery(variables: ActivityLogQueryVariables | VueCompositionApi.Ref<ActivityLogQueryVariables> | ReactiveFunction<ActivityLogQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ActivityLogQuery, ActivityLogQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ActivityLogQuery, ActivityLogQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ActivityLogQuery, ActivityLogQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ActivityLogQuery, ActivityLogQueryVariables>(ActivityLogDocument, variables, options);
}
export function useActivityLogLazyQuery(variables: ActivityLogQueryVariables | VueCompositionApi.Ref<ActivityLogQueryVariables> | ReactiveFunction<ActivityLogQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ActivityLogQuery, ActivityLogQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ActivityLogQuery, ActivityLogQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ActivityLogQuery, ActivityLogQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ActivityLogQuery, ActivityLogQueryVariables>(ActivityLogDocument, variables, options);
}
export type ActivityLogQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ActivityLogQuery, ActivityLogQueryVariables>;
export const ActivityLogFiltersDocument = gql`
    query activityLogFilters {
  organizations {
    uuid
    title
  }
  projects {
    uuid
    title
  }
  users {
    uuid
    name
  }
}
    `;

/**
 * __useActivityLogFiltersQuery__
 *
 * To run a query within a Vue component, call `useActivityLogFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogFiltersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useActivityLogFiltersQuery();
 */
export function useActivityLogFiltersQuery(options: VueApolloComposable.UseQueryOptions<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables>(ActivityLogFiltersDocument, {}, options);
}
export function useActivityLogFiltersLazyQuery(options: VueApolloComposable.UseQueryOptions<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables>(ActivityLogFiltersDocument, {}, options);
}
export type ActivityLogFiltersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ActivityLogFiltersQuery, ActivityLogFiltersQueryVariables>;
export const ActivityLogForDrawingDocument = gql`
    query activityLogForDrawing($uuid: Uuid!) {
  activityLogForDrawing(uuid: $uuid) {
    uuid
    type
    created_at
    user {
      uuid
      name
    }
  }
}
    `;

/**
 * __useActivityLogForDrawingQuery__
 *
 * To run a query within a Vue component, call `useActivityLogForDrawingQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLogForDrawingQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useActivityLogForDrawingQuery({
 *   uuid: // value for 'uuid'
 * });
 */
export function useActivityLogForDrawingQuery(variables: ActivityLogForDrawingQueryVariables | VueCompositionApi.Ref<ActivityLogForDrawingQueryVariables> | ReactiveFunction<ActivityLogForDrawingQueryVariables>, options: VueApolloComposable.UseQueryOptions<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables>(ActivityLogForDrawingDocument, variables, options);
}
export function useActivityLogForDrawingLazyQuery(variables?: ActivityLogForDrawingQueryVariables | VueCompositionApi.Ref<ActivityLogForDrawingQueryVariables> | ReactiveFunction<ActivityLogForDrawingQueryVariables>, options: VueApolloComposable.UseQueryOptions<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables>(ActivityLogForDrawingDocument, variables, options);
}
export type ActivityLogForDrawingQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ActivityLogForDrawingQuery, ActivityLogForDrawingQueryVariables>;
export const MemberActivityLogFiltersDocument = gql`
    query memberActivityLogFilters {
  organizations {
    uuid
    title
  }
  projects {
    uuid
    title
  }
}
    `;

/**
 * __useMemberActivityLogFiltersQuery__
 *
 * To run a query within a Vue component, call `useMemberActivityLogFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberActivityLogFiltersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMemberActivityLogFiltersQuery();
 */
export function useMemberActivityLogFiltersQuery(options: VueApolloComposable.UseQueryOptions<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables>(MemberActivityLogFiltersDocument, {}, options);
}
export function useMemberActivityLogFiltersLazyQuery(options: VueApolloComposable.UseQueryOptions<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables>(MemberActivityLogFiltersDocument, {}, options);
}
export type MemberActivityLogFiltersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MemberActivityLogFiltersQuery, MemberActivityLogFiltersQueryVariables>;
export const BulkDrawingExportDocument = gql`
    query bulkDrawingExport($uuids: [Uuid!]!) {
  bulkDrawingExport(uuids: $uuids)
}
    `;

/**
 * __useBulkDrawingExportQuery__
 *
 * To run a query within a Vue component, call `useBulkDrawingExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkDrawingExportQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBulkDrawingExportQuery({
 *   uuids: // value for 'uuids'
 * });
 */
export function useBulkDrawingExportQuery(variables: BulkDrawingExportQueryVariables | VueCompositionApi.Ref<BulkDrawingExportQueryVariables> | ReactiveFunction<BulkDrawingExportQueryVariables>, options: VueApolloComposable.UseQueryOptions<BulkDrawingExportQuery, BulkDrawingExportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BulkDrawingExportQuery, BulkDrawingExportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BulkDrawingExportQuery, BulkDrawingExportQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BulkDrawingExportQuery, BulkDrawingExportQueryVariables>(BulkDrawingExportDocument, variables, options);
}
export function useBulkDrawingExportLazyQuery(variables?: BulkDrawingExportQueryVariables | VueCompositionApi.Ref<BulkDrawingExportQueryVariables> | ReactiveFunction<BulkDrawingExportQueryVariables>, options: VueApolloComposable.UseQueryOptions<BulkDrawingExportQuery, BulkDrawingExportQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BulkDrawingExportQuery, BulkDrawingExportQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BulkDrawingExportQuery, BulkDrawingExportQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BulkDrawingExportQuery, BulkDrawingExportQueryVariables>(BulkDrawingExportDocument, variables, options);
}
export type BulkDrawingExportQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BulkDrawingExportQuery, BulkDrawingExportQueryVariables>;
export const BulkQrCodesDocument = gql`
    query bulkQrCodes($uuids: [Uuid!]!) {
  bulkQrCodes(uuids: $uuids)
}
    `;

/**
 * __useBulkQrCodesQuery__
 *
 * To run a query within a Vue component, call `useBulkQrCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkQrCodesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBulkQrCodesQuery({
 *   uuids: // value for 'uuids'
 * });
 */
export function useBulkQrCodesQuery(variables: BulkQrCodesQueryVariables | VueCompositionApi.Ref<BulkQrCodesQueryVariables> | ReactiveFunction<BulkQrCodesQueryVariables>, options: VueApolloComposable.UseQueryOptions<BulkQrCodesQuery, BulkQrCodesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BulkQrCodesQuery, BulkQrCodesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BulkQrCodesQuery, BulkQrCodesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BulkQrCodesQuery, BulkQrCodesQueryVariables>(BulkQrCodesDocument, variables, options);
}
export function useBulkQrCodesLazyQuery(variables?: BulkQrCodesQueryVariables | VueCompositionApi.Ref<BulkQrCodesQueryVariables> | ReactiveFunction<BulkQrCodesQueryVariables>, options: VueApolloComposable.UseQueryOptions<BulkQrCodesQuery, BulkQrCodesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BulkQrCodesQuery, BulkQrCodesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BulkQrCodesQuery, BulkQrCodesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BulkQrCodesQuery, BulkQrCodesQueryVariables>(BulkQrCodesDocument, variables, options);
}
export type BulkQrCodesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BulkQrCodesQuery, BulkQrCodesQueryVariables>;
export const DrawingDocument = gql`
    query drawing($uuid: Uuid!, $access_token: String) {
  drawing(uuid: $uuid, access_token: $access_token) {
    uuid
    title
    description
    published
    confidential
    discipline {
      id
      title
    }
    floor {
      id
      title
    }
    type {
      id
      title
    }
    locations {
      uuid
      published
      title
    }
    location {
      uuid
      published
      title
      drawings {
        uuid
        title
        discipline {
          id
          title
        }
        floor {
          id
          title
        }
        type {
          id
          title
        }
      }
    }
    project {
      uuid
      published
      title
      organization {
        uuid
        title
      }
    }
    latest_version {
      uuid
    }
    versions {
      uuid
      title
    }
  }
}
    `;

/**
 * __useDrawingQuery__
 *
 * To run a query within a Vue component, call `useDrawingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawingQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDrawingQuery({
 *   uuid: // value for 'uuid'
 *   access_token: // value for 'access_token'
 * });
 */
export function useDrawingQuery(variables: DrawingQueryVariables | VueCompositionApi.Ref<DrawingQueryVariables> | ReactiveFunction<DrawingQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingQuery, DrawingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingQuery, DrawingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingQuery, DrawingQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DrawingQuery, DrawingQueryVariables>(DrawingDocument, variables, options);
}
export function useDrawingLazyQuery(variables?: DrawingQueryVariables | VueCompositionApi.Ref<DrawingQueryVariables> | ReactiveFunction<DrawingQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingQuery, DrawingQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingQuery, DrawingQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingQuery, DrawingQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DrawingQuery, DrawingQueryVariables>(DrawingDocument, variables, options);
}
export type DrawingQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DrawingQuery, DrawingQueryVariables>;
export const DrawingAccessTokenDocument = gql`
    query drawingAccessToken($uuid: Uuid!) {
  drawingAccessToken(uuid: $uuid)
}
    `;

/**
 * __useDrawingAccessTokenQuery__
 *
 * To run a query within a Vue component, call `useDrawingAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawingAccessTokenQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDrawingAccessTokenQuery({
 *   uuid: // value for 'uuid'
 * });
 */
export function useDrawingAccessTokenQuery(variables: DrawingAccessTokenQueryVariables | VueCompositionApi.Ref<DrawingAccessTokenQueryVariables> | ReactiveFunction<DrawingAccessTokenQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables>(DrawingAccessTokenDocument, variables, options);
}
export function useDrawingAccessTokenLazyQuery(variables?: DrawingAccessTokenQueryVariables | VueCompositionApi.Ref<DrawingAccessTokenQueryVariables> | ReactiveFunction<DrawingAccessTokenQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables>(DrawingAccessTokenDocument, variables, options);
}
export type DrawingAccessTokenQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DrawingAccessTokenQuery, DrawingAccessTokenQueryVariables>;
export const DrawingVersionDocument = gql`
    query drawingVersion($drawingUuid: Uuid!, $versionUuid: Uuid, $accessToken: String) {
  drawingVersion(
    drawing_uuid: $drawingUuid
    version_uuid: $versionUuid
    access_token: $accessToken
  ) {
    uuid
    drawing
    status
    change_requests {
      is_sent
      suggestions {
        id
        title
        x
        y
        created_at
        comment
        symbol
        images
        type
        status
        is_sent
        page_index
        reporter {
          uuid
          name
        }
      }
      links {
        id
        title
        x
        y
        created_at
        type
        page_index
        reporter {
          uuid
          name
        }
        drawing {
          uuid
          title
          version
        }
      }
    }
  }
}
    `;

/**
 * __useDrawingVersionQuery__
 *
 * To run a query within a Vue component, call `useDrawingVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawingVersionQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDrawingVersionQuery({
 *   drawingUuid: // value for 'drawingUuid'
 *   versionUuid: // value for 'versionUuid'
 *   accessToken: // value for 'accessToken'
 * });
 */
export function useDrawingVersionQuery(variables: DrawingVersionQueryVariables | VueCompositionApi.Ref<DrawingVersionQueryVariables> | ReactiveFunction<DrawingVersionQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingVersionQuery, DrawingVersionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingVersionQuery, DrawingVersionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingVersionQuery, DrawingVersionQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DrawingVersionQuery, DrawingVersionQueryVariables>(DrawingVersionDocument, variables, options);
}
export function useDrawingVersionLazyQuery(variables?: DrawingVersionQueryVariables | VueCompositionApi.Ref<DrawingVersionQueryVariables> | ReactiveFunction<DrawingVersionQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingVersionQuery, DrawingVersionQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingVersionQuery, DrawingVersionQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingVersionQuery, DrawingVersionQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DrawingVersionQuery, DrawingVersionQueryVariables>(DrawingVersionDocument, variables, options);
}
export type DrawingVersionQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DrawingVersionQuery, DrawingVersionQueryVariables>;
export const DrawingsDocument = gql`
    query drawings($location: Uuid!, $filters: DrawingFilters, $sort: String) {
  drawings(location: $location, filters: $filters, sort: $sort) {
    uuid
    title
    description
    updated_at
    published
    confidential
    discipline {
      id
      title
    }
    floor {
      id
      title
    }
    type {
      id
      title
    }
    latest_version {
      title
      change_requests {
        status
        suggestions {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useDrawingsQuery__
 *
 * To run a query within a Vue component, call `useDrawingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrawingsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDrawingsQuery({
 *   location: // value for 'location'
 *   filters: // value for 'filters'
 *   sort: // value for 'sort'
 * });
 */
export function useDrawingsQuery(variables: DrawingsQueryVariables | VueCompositionApi.Ref<DrawingsQueryVariables> | ReactiveFunction<DrawingsQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingsQuery, DrawingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingsQuery, DrawingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingsQuery, DrawingsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DrawingsQuery, DrawingsQueryVariables>(DrawingsDocument, variables, options);
}
export function useDrawingsLazyQuery(variables?: DrawingsQueryVariables | VueCompositionApi.Ref<DrawingsQueryVariables> | ReactiveFunction<DrawingsQueryVariables>, options: VueApolloComposable.UseQueryOptions<DrawingsQuery, DrawingsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DrawingsQuery, DrawingsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DrawingsQuery, DrawingsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DrawingsQuery, DrawingsQueryVariables>(DrawingsDocument, variables, options);
}
export type DrawingsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DrawingsQuery, DrawingsQueryVariables>;
export const UserAccessForDrawingQueryDocument = gql`
    query userAccessForDrawingQuery($drawing: Uuid!) {
  userAccessForDrawing(drawing: $drawing) {
    uuid
    name
    email
    role
  }
}
    `;

/**
 * __useUserAccessForDrawingQueryQuery__
 *
 * To run a query within a Vue component, call `useUserAccessForDrawingQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccessForDrawingQueryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserAccessForDrawingQueryQuery({
 *   drawing: // value for 'drawing'
 * });
 */
export function useUserAccessForDrawingQueryQuery(variables: UserAccessForDrawingQueryQueryVariables | VueCompositionApi.Ref<UserAccessForDrawingQueryQueryVariables> | ReactiveFunction<UserAccessForDrawingQueryQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables>(UserAccessForDrawingQueryDocument, variables, options);
}
export function useUserAccessForDrawingQueryLazyQuery(variables?: UserAccessForDrawingQueryQueryVariables | VueCompositionApi.Ref<UserAccessForDrawingQueryQueryVariables> | ReactiveFunction<UserAccessForDrawingQueryQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables>(UserAccessForDrawingQueryDocument, variables, options);
}
export type UserAccessForDrawingQueryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserAccessForDrawingQueryQuery, UserAccessForDrawingQueryQueryVariables>;
export const LocationDocument = gql`
    query location($uuid: Uuid!) {
  location(uuid: $uuid) {
    uuid
    title
    published
    drawings {
      uuid
      title
      updated_at
      discipline {
        id
        title
      }
      floor {
        id
        title
      }
      type {
        id
        title
      }
      latest_version {
        version: title
        change_requests {
          suggestions_count
        }
      }
    }
    address {
      street
      city
      house_number
      house_number_suffix
      zipcode
    }
    projects {
      uuid
      organization {
        uuid
        title
        users_count
      }
    }
  }
}
    `;

/**
 * __useLocationQuery__
 *
 * To run a query within a Vue component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLocationQuery({
 *   uuid: // value for 'uuid'
 * });
 */
export function useLocationQuery(variables: LocationQueryVariables | VueCompositionApi.Ref<LocationQueryVariables> | ReactiveFunction<LocationQueryVariables>, options: VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, variables, options);
}
export function useLocationLazyQuery(variables?: LocationQueryVariables | VueCompositionApi.Ref<LocationQueryVariables> | ReactiveFunction<LocationQueryVariables>, options: VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, variables, options);
}
export type LocationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LocationQuery, LocationQueryVariables>;
export const LocationsDocument = gql`
    query locations($project: Uuid!, $query: String) {
  locations(project: $project, query: $query) {
    uuid
    title
    total_suggestions_count
    drawings_count
    updated_at
    published
    address {
      address_string
      street
      city
      house_number
      house_number_suffix
      zipcode
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a Vue component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLocationsQuery({
 *   project: // value for 'project'
 *   query: // value for 'query'
 * });
 */
export function useLocationsQuery(variables: LocationsQueryVariables | VueCompositionApi.Ref<LocationsQueryVariables> | ReactiveFunction<LocationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, variables, options);
}
export function useLocationsLazyQuery(variables?: LocationsQueryVariables | VueCompositionApi.Ref<LocationsQueryVariables> | ReactiveFunction<LocationsQueryVariables>, options: VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, variables, options);
}
export type LocationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LocationsQuery, LocationsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    uuid
    email
    name
    two_factor_enabled
    role
    can_export_drawings
    organization {
      uuid
      title
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const OrganizationDocument = gql`
    query organization($uuid: Uuid!) {
  organization(uuid: $uuid) {
    uuid
    title
    address {
      city
      country
      house_number
      house_number_suffix
      latitude
      longitude
      street
      zipcode
    }
    users {
      uuid
      name
      email
      role
    }
  }
}
    `;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a Vue component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationQuery({
 *   uuid: // value for 'uuid'
 * });
 */
export function useOrganizationQuery(variables: OrganizationQueryVariables | VueCompositionApi.Ref<OrganizationQueryVariables> | ReactiveFunction<OrganizationQueryVariables>, options: VueApolloComposable.UseQueryOptions<OrganizationQuery, OrganizationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OrganizationQuery, OrganizationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OrganizationQuery, OrganizationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, variables, options);
}
export function useOrganizationLazyQuery(variables?: OrganizationQueryVariables | VueCompositionApi.Ref<OrganizationQueryVariables> | ReactiveFunction<OrganizationQueryVariables>, options: VueApolloComposable.UseQueryOptions<OrganizationQuery, OrganizationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OrganizationQuery, OrganizationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OrganizationQuery, OrganizationQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, variables, options);
}
export type OrganizationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OrganizationQuery, OrganizationQueryVariables>;
export const OrganizationsDocument = gql`
    query organizations($query: String, $sort: String) {
  organizations(query: $query, sort: $sort) {
    uuid
    title
    users_count
    address {
      address_string
    }
  }
}
    `;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a Vue component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrganizationsQuery({
 *   query: // value for 'query'
 *   sort: // value for 'sort'
 * });
 */
export function useOrganizationsQuery(variables: OrganizationsQueryVariables | VueCompositionApi.Ref<OrganizationsQueryVariables> | ReactiveFunction<OrganizationsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<OrganizationsQuery, OrganizationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OrganizationsQuery, OrganizationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OrganizationsQuery, OrganizationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, variables, options);
}
export function useOrganizationsLazyQuery(variables: OrganizationsQueryVariables | VueCompositionApi.Ref<OrganizationsQueryVariables> | ReactiveFunction<OrganizationsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<OrganizationsQuery, OrganizationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OrganizationsQuery, OrganizationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OrganizationsQuery, OrganizationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, variables, options);
}
export type OrganizationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OrganizationsQuery, OrganizationsQueryVariables>;
export const DisciplinesDocument = gql`
    query disciplines($locationUuid: Uuid) {
  disciplines(location_uuid: $locationUuid) {
    id
    title
  }
}
    `;

/**
 * __useDisciplinesQuery__
 *
 * To run a query within a Vue component, call `useDisciplinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisciplinesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDisciplinesQuery({
 *   locationUuid: // value for 'locationUuid'
 * });
 */
export function useDisciplinesQuery(variables: DisciplinesQueryVariables | VueCompositionApi.Ref<DisciplinesQueryVariables> | ReactiveFunction<DisciplinesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<DisciplinesQuery, DisciplinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DisciplinesQuery, DisciplinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DisciplinesQuery, DisciplinesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DisciplinesQuery, DisciplinesQueryVariables>(DisciplinesDocument, variables, options);
}
export function useDisciplinesLazyQuery(variables: DisciplinesQueryVariables | VueCompositionApi.Ref<DisciplinesQueryVariables> | ReactiveFunction<DisciplinesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<DisciplinesQuery, DisciplinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DisciplinesQuery, DisciplinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DisciplinesQuery, DisciplinesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DisciplinesQuery, DisciplinesQueryVariables>(DisciplinesDocument, variables, options);
}
export type DisciplinesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DisciplinesQuery, DisciplinesQueryVariables>;
export const FloorsDocument = gql`
    query floors($locationUuid: Uuid) {
  floors(location_uuid: $locationUuid) {
    id
    title
  }
}
    `;

/**
 * __useFloorsQuery__
 *
 * To run a query within a Vue component, call `useFloorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFloorsQuery({
 *   locationUuid: // value for 'locationUuid'
 * });
 */
export function useFloorsQuery(variables: FloorsQueryVariables | VueCompositionApi.Ref<FloorsQueryVariables> | ReactiveFunction<FloorsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FloorsQuery, FloorsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FloorsQuery, FloorsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FloorsQuery, FloorsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FloorsQuery, FloorsQueryVariables>(FloorsDocument, variables, options);
}
export function useFloorsLazyQuery(variables: FloorsQueryVariables | VueCompositionApi.Ref<FloorsQueryVariables> | ReactiveFunction<FloorsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FloorsQuery, FloorsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FloorsQuery, FloorsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FloorsQuery, FloorsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FloorsQuery, FloorsQueryVariables>(FloorsDocument, variables, options);
}
export type FloorsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FloorsQuery, FloorsQueryVariables>;
export const TypesDocument = gql`
    query types($locationUuid: Uuid) {
  types(location_uuid: $locationUuid) {
    id
    title
  }
}
    `;

/**
 * __useTypesQuery__
 *
 * To run a query within a Vue component, call `useTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTypesQuery({
 *   locationUuid: // value for 'locationUuid'
 * });
 */
export function useTypesQuery(variables: TypesQueryVariables | VueCompositionApi.Ref<TypesQueryVariables> | ReactiveFunction<TypesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TypesQuery, TypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TypesQuery, TypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TypesQuery, TypesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TypesQuery, TypesQueryVariables>(TypesDocument, variables, options);
}
export function useTypesLazyQuery(variables: TypesQueryVariables | VueCompositionApi.Ref<TypesQueryVariables> | ReactiveFunction<TypesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TypesQuery, TypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TypesQuery, TypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TypesQuery, TypesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TypesQuery, TypesQueryVariables>(TypesDocument, variables, options);
}
export type TypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TypesQuery, TypesQueryVariables>;
export const ProjectDocument = gql`
    query project($uuid: Uuid!) {
  project(uuid: $uuid) {
    uuid
    title
    published
    organization {
      uuid
      title
      users_count
    }
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a Vue component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectQuery({
 *   uuid: // value for 'uuid'
 * });
 */
export function useProjectQuery(variables: ProjectQueryVariables | VueCompositionApi.Ref<ProjectQueryVariables> | ReactiveFunction<ProjectQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, variables, options);
}
export function useProjectLazyQuery(variables?: ProjectQueryVariables | VueCompositionApi.Ref<ProjectQueryVariables> | ReactiveFunction<ProjectQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProjectQuery, ProjectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, variables, options);
}
export type ProjectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProjectQuery, ProjectQueryVariables>;
export const ProjectsDocument = gql`
    query projects($organization: Uuid, $query: String, $sort: String) {
  projects(organization: $organization, query: $query, sort: $sort) {
    uuid
    title
    published
    locations_count
    users_count
    organization {
      uuid
      title
    }
    locations {
      uuid
      title
      published
      drawings {
        uuid
        title
      }
    }
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a Vue component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProjectsQuery({
 *   organization: // value for 'organization'
 *   query: // value for 'query'
 *   sort: // value for 'sort'
 * });
 */
export function useProjectsQuery(variables: ProjectsQueryVariables | VueCompositionApi.Ref<ProjectsQueryVariables> | ReactiveFunction<ProjectsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProjectsQuery, ProjectsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProjectsQuery, ProjectsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProjectsQuery, ProjectsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, variables, options);
}
export function useProjectsLazyQuery(variables: ProjectsQueryVariables | VueCompositionApi.Ref<ProjectsQueryVariables> | ReactiveFunction<ProjectsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProjectsQuery, ProjectsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProjectsQuery, ProjectsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProjectsQuery, ProjectsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, variables, options);
}
export type ProjectsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProjectsQuery, ProjectsQueryVariables>;
export const PageDocument = gql`
    query page($segments: String!) {
  page(segments: $segments) {
    active_image
    seo {
      meta_title
      meta_description
      meta_index
      meta_follow
    }
    slug
    title
    url
    template {
      __typename
      ... on TemplateLandingspagina {
        hero_title
        hero_text
        hero_image
        examples {
          title
          text
        }
        text_image_title
        text_image_text
        text_image_image
        steps {
          title
          text
          image
        }
        costs_title
        costs_text
        costs_package_price
        costs_package_price_per
        costs_package_title
        costs_package_usps
        demo_title
        demo_text
        demo_form {
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a Vue component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageQuery({
 *   segments: // value for 'segments'
 * });
 */
export function usePageQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export function usePageLazyQuery(variables?: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export type PageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageQuery, PageQueryVariables>;
export const UserDocument = gql`
    query user($uuid: Uuid!) {
  user(uuid: $uuid) {
    uuid
    email
    phone_number
    name
    two_factor_enabled
    first_name
    last_name
    published
    role
    last_login
    can_export_drawings
    organization {
      uuid
      title
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a Vue component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserQuery({
 *   uuid: // value for 'uuid'
 * });
 */
export function useUserQuery(variables: UserQueryVariables | VueCompositionApi.Ref<UserQueryVariables> | ReactiveFunction<UserQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserQuery, UserQueryVariables>(UserDocument, variables, options);
}
export function useUserLazyQuery(variables?: UserQueryVariables | VueCompositionApi.Ref<UserQueryVariables> | ReactiveFunction<UserQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, variables, options);
}
export type UserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($query: String, $sort: String) {
  users(query: $query, sort: $sort) {
    uuid
    email
    phone_number
    name
    role
    organization {
      uuid
      title
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a Vue component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUsersQuery({
 *   query: // value for 'query'
 *   sort: // value for 'sort'
 * });
 */
export function useUsersQuery(variables: UsersQueryVariables | VueCompositionApi.Ref<UsersQueryVariables> | ReactiveFunction<UsersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options);
}
export function useUsersLazyQuery(variables: UsersQueryVariables | VueCompositionApi.Ref<UsersQueryVariables> | ReactiveFunction<UsersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options);
}
export type UsersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UsersQuery, UsersQueryVariables>;
export const UserAccessDocument = gql`
    query userAccess($user: Uuid!) {
  userAccess(user: $user) {
    uuid
    title
    accessible
    locations: entities {
      uuid
      title
      accessible
      drawings: entities {
        uuid
        title
        accessible
      }
    }
  }
}
    `;

/**
 * __useUserAccessQuery__
 *
 * To run a query within a Vue component, call `useUserAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAccessQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserAccessQuery({
 *   user: // value for 'user'
 * });
 */
export function useUserAccessQuery(variables: UserAccessQueryVariables | VueCompositionApi.Ref<UserAccessQueryVariables> | ReactiveFunction<UserAccessQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserAccessQuery, UserAccessQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserAccessQuery, UserAccessQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserAccessQuery, UserAccessQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserAccessQuery, UserAccessQueryVariables>(UserAccessDocument, variables, options);
}
export function useUserAccessLazyQuery(variables?: UserAccessQueryVariables | VueCompositionApi.Ref<UserAccessQueryVariables> | ReactiveFunction<UserAccessQueryVariables>, options: VueApolloComposable.UseQueryOptions<UserAccessQuery, UserAccessQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserAccessQuery, UserAccessQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserAccessQuery, UserAccessQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserAccessQuery, UserAccessQueryVariables>(UserAccessDocument, variables, options);
}
export type UserAccessQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserAccessQuery, UserAccessQueryVariables>;