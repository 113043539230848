const search = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 18"><path fill="#374151" fill-rule="evenodd" d="M8.16 3.6a3.6 3.6 0 1 0 0 7.2 3.6 3.6 0 0 0 0-7.2Zm-5.4 3.6a5.4 5.4 0 1 1 9.8 3.13l4.34 4.33a.9.9 0 0 1-1.27 1.28l-4.34-4.34a5.4 5.4 0 0 1-8.53-4.4Z" clip-rule="evenodd"/></svg>';

const checkbox = '<svg  viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg"><path d="M21.1297 0.828115C21.6078 1.26874 21.6078 1.98124 21.1297 2.37968L8.75469 14.7547C8.35625 15.2328 7.64375 15.2328 7.20312 14.7547L0.829484 8.37968C0.390172 7.98124 0.390172 7.26874 0.829484 6.82812C1.26875 6.39218 1.98125 6.39218 2.42047 6.82812L8 12.4109L19.5781 0.828115C20.0187 0.390303 20.7313 0.390303 21.1297 0.828115Z" fill="currentColor"/></svg>';

const fileRemove = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"/></svg>';

const select = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path fill="#6B7280" fill-rule="evenodd" d="M3.22 6.22a.75.75 0 0 1 1.06 0L9 10.94l4.72-4.72a.75.75 0 1 1 1.06 1.06l-5.25 5.25a.75.75 0 0 1-1.06 0L3.22 7.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"/></svg>';

/**
 * Object with default FormKit icons
 * @return {{select?: string, search?: string, checkboxDecorator?: string, radioDecorator?: string}}
 */

export const formKitIcons = {
  search,
  checkboxDecorator: checkbox,
  fileRemove,
  select,
};
