import { default as _91_46_46_46page_93AbgEo45eGGMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/[...page].vue?macro=true";
import { default as loginssGF3188OOMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/auth/login.vue?macro=true";
import { default as request_45passwordW6htuHN6d0Meta } from "/data/www/er-box.nl/er-box/nuxt/pages/auth/request-password.vue?macro=true";
import { default as _91token_93iSIrmHUdXQMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91token_93w8YShVDNF6Meta } from "/data/www/er-box.nl/er-box/nuxt/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91uuid_93WP7UD0FrlvMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/gebruikers/[uuid].vue?macro=true";
import { default as indexJ0HdHyKw2RMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/gebruikers/index.vue?macro=true";
import { default as index45h4kAv4pjMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/index.vue?macro=true";
import { default as _91uuid_93aFMvwMItDSMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/organisaties/[uuid].vue?macro=true";
import { default as indextypBYC9S90Meta } from "/data/www/er-box.nl/er-box/nuxt/pages/organisaties/index.vue?macro=true";
import { default as indexTfMURzSOB3Meta } from "/data/www/er-box.nl/er-box/nuxt/pages/projecten/[project]/[locatie]/index.vue?macro=true";
import { default as indexRcF8jyRD9AMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/projecten/[project]/index.vue?macro=true";
import { default as indexNBYYef7s1iMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/projecten/index.vue?macro=true";
import { default as _91uuid_93180t0ksGLOMeta } from "/data/www/er-box.nl/er-box/nuxt/pages/tekening/[uuid].vue?macro=true";
export default [
  {
    name: "page",
    path: "/:page(.*)*",
    meta: _91_46_46_46page_93AbgEo45eGGMeta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginssGF3188OOMeta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-request-password",
    path: "/auth/request-password",
    meta: request_45passwordW6htuHN6d0Meta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token()",
    meta: _91token_93iSIrmHUdXQMeta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93w8YShVDNF6Meta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/auth/wachtwoord-instellen/[token].vue").then(m => m.default || m)
  },
  {
    name: "gebruikers-uuid",
    path: "/gebruikers/:uuid()",
    meta: _91uuid_93WP7UD0FrlvMeta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/gebruikers/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "gebruikers",
    path: "/gebruikers",
    meta: indexJ0HdHyKw2RMeta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/gebruikers/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "organisaties-uuid",
    path: "/organisaties/:uuid()",
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/organisaties/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "organisaties",
    path: "/organisaties",
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: "projecten-project-locatie",
    path: "/projecten/:project()/:locatie()",
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/projecten/[project]/[locatie]/index.vue").then(m => m.default || m)
  },
  {
    name: "projecten-project",
    path: "/projecten/:project()",
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/projecten/[project]/index.vue").then(m => m.default || m)
  },
  {
    name: "projecten",
    path: "/projecten",
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/projecten/index.vue").then(m => m.default || m)
  },
  {
    name: "tekening-uuid",
    path: "/tekening/:uuid()",
    meta: _91uuid_93180t0ksGLOMeta || {},
    component: () => import("/data/www/er-box.nl/er-box/nuxt/pages/tekening/[uuid].vue").then(m => m.default || m)
  }
]