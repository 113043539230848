<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
useHead({
  titleTemplate: (title) => {
    return title ? `${title} - ER-BOX` : 'ER-BOX';
  },
});
</script>
