<template>
  <NuxtLayout name="default">
    <div class="flex h-full flex-col items-center justify-center gap-5">
      <h1 class="text-6xl font-bold">
        {{ error.statusCode }}
      </h1>
      <p class="mb-5 text-3xl font-bold">
        <template v-if="error.statusCode === 401">
          Deze pagina is afgeschermd
        </template>
        <template v-else-if="error.statusCode === 403">
          Je hebt geen toegang (meer) tot deze pagina
        </template>
        <template v-else-if="error.statusCode === 404">
          We kunnen deze pagina niet (meer) vinden
        </template>
        <template v-else>
          Er ging iets mis
        </template>
      </p>

      <UiButton
        label="Terug naar dashboard"
        @click="handleError"
      />
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}
</script>
