<template>
  <div>
    <div class="overflow-hidden rounded-2xl border border-gray-200 bg-gray-100">
      <div class="absolute flex gap-x-2 p-2">
        <UiButton
          type="outline"
          class="size-8 !rounded-md !p-0"
          @click="undo(); save();"
        >
          <UiIcon
            size="small"
            name="Undo"
          />
        </UiButton>
        <UiButton
          type="outline"
          class="size-8 !rounded-md !p-0"
          @click="clear"
        >
          <UiIcon
            size="small"
            name="Trash"
          />
        </UiButton>
      </div>
      <UiSignaturePad
        ref="symbol"
        w="100%"
        h="200px"
        :background="initialSignature"
        @end="save"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const symbol = ref();

const initialSignature = computed(() => props.context.attrs['initial-signature'] ?? null);

function undo() {
  symbol.value.undo();

  props.context.node.input(symbol.value);
}

function clear() {
  symbol.value.clear();

  props.context.node.input(null);
}

function save() {
  const savedValue = symbol.value.save('image/jpeg');

  props.context.node.input(savedValue);
}
</script>
