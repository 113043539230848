import revive_payload_client_wu4sDeKNl6 from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_d8rff9A1Dk from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_vQjlkAYhFm from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_kXRbQI8ff2 from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.2_vite@5.3.5_@types+node@22.0.2_terser@5.3_ft5aop7izdetavbbgzzeabykk4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8XnnggLkvF from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Ob9Ulx3CFp from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kEQhENBgap from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YIcjWnhUuU from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/er-box.nl/er-box/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_ssipQPtE9S from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.2_encoding@0.1.13_eslint@8.57.0_ioredis@5._xhalstitv2akicd3yxbhs7ztw4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3zs4991mpy from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.19.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_bjIZtkR27D from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.3.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_SJIjGNWAhq from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.19.2_vue@3.4.35_typescript@5.3.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/er-box.nl/er-box/nuxt/.nuxt/formkitPlugin.mjs";
import plugin_pE3W23qin5 from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.8.2__react-dom@18.2.0_react@_tyw3vjfnbyx2gxsghboufyypha/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_XyCAt8TSTb from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_encoding@0.1.13_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+_maas7strcva3ocv7izwlxhu3ny/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import errors_IUKiRKlppU from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_diuxdgbcnldbyptgllfyxsa3cm/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_85rZ843sdJ from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_diuxdgbcnldbyptgllfyxsa3cm/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_Awjxy2zVR8 from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_zz7ympmh7wlpqzkf5llxdu4qxq/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_xFByK2qOyd from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.3_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.8.2__magi_zz7ympmh7wlpqzkf5llxdu4qxq/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_ZZruHdLK7Y from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@bugsnag+core@7.25.0_encoding@0.1.13_graphql-ws@5.16.0_666cjpsfo5ax3j2lecfyknzity/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_SWXuqUBZPn from "/data/www/er-box.nl/er-box/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@bugsnag+core@7.25.0_encoding@0.1.13_graphql-ws@5.16.0_666cjpsfo5ax3j2lecfyknzity/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import dynamic_vw_client_kS7b5RjaKG from "/data/www/er-box.nl/er-box/nuxt/plugins/dynamic-vw.client.ts";
import leaflet_client_7dEOJA4hbk from "/data/www/er-box.nl/er-box/nuxt/plugins/leaflet.client.ts";
import vue3_popper_client_4mGDrhCm4m from "/data/www/er-box.nl/er-box/nuxt/plugins/vue3-popper.client.ts";
export default [
  revive_payload_client_wu4sDeKNl6,
  unhead_d8rff9A1Dk,
  router_vQjlkAYhFm,
  _0_siteConfig_kXRbQI8ff2,
  payload_client_8XnnggLkvF,
  navigation_repaint_client_Ob9Ulx3CFp,
  check_outdated_build_client_kEQhENBgap,
  chunk_reload_client_YIcjWnhUuU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ssipQPtE9S,
  plugin_3zs4991mpy,
  switch_locale_path_ssr_bjIZtkR27D,
  i18n_SJIjGNWAhq,
  formkitPlugin_pZqjah0RUG,
  plugin_pE3W23qin5,
  plugin_XyCAt8TSTb,
  errors_IUKiRKlppU,
  i18n_85rZ843sdJ,
  apollo_Awjxy2zVR8,
  sofie_required_version_xFByK2qOyd,
  apollo_ZZruHdLK7Y,
  auth_SWXuqUBZPn,
  dynamic_vw_client_kS7b5RjaKG,
  leaflet_client_7dEOJA4hbk,
  vue3_popper_client_4mGDrhCm4m
]