import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArrowLeft, LazyArrowRight, LazyArrowsCollapse, LazyArrowsExpand, LazyCheck, LazyCheckCircle, LazyChevronDown, LazyChevronRight, LazyClipboard, LazyClipboardList, LazyCog, LazyDashboard, LazyDocumentText, LazyEnvelope, LazyExclamation, LazyEye, LazyEyeHidden, LazyFilter, LazyHamburger, LazyImage, LazyLinkIcon, LazyLocationMarker, LazyLock, LazyLockOpen, LazyLogIn, LazyLogOut, LazyMarker, LazyOfficeBuilding, LazyPencil, LazyPhotograph, LazyPlusCircle, LazyQrCode, LazySave, LazySort, LazyTrash, LazyUndo, LazyUpload, LazyUser, LazyUserGroup, LazyXMark, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ArrowLeft", LazyArrowLeft],
["ArrowRight", LazyArrowRight],
["ArrowsCollapse", LazyArrowsCollapse],
["ArrowsExpand", LazyArrowsExpand],
["Check", LazyCheck],
["CheckCircle", LazyCheckCircle],
["ChevronDown", LazyChevronDown],
["ChevronRight", LazyChevronRight],
["Clipboard", LazyClipboard],
["ClipboardList", LazyClipboardList],
["Cog", LazyCog],
["Dashboard", LazyDashboard],
["DocumentText", LazyDocumentText],
["Envelope", LazyEnvelope],
["Exclamation", LazyExclamation],
["Eye", LazyEye],
["EyeHidden", LazyEyeHidden],
["Filter", LazyFilter],
["Hamburger", LazyHamburger],
["Image", LazyImage],
["LinkIcon", LazyLinkIcon],
["LocationMarker", LazyLocationMarker],
["Lock", LazyLock],
["LockOpen", LazyLockOpen],
["LogIn", LazyLogIn],
["LogOut", LazyLogOut],
["Marker", LazyMarker],
["OfficeBuilding", LazyOfficeBuilding],
["Pencil", LazyPencil],
["Photograph", LazyPhotograph],
["PlusCircle", LazyPlusCircle],
["QrCode", LazyQrCode],
["Save", LazySave],
["Sort", LazySort],
["Trash", LazyTrash],
["Undo", LazyUndo],
["Upload", LazyUpload],
["User", LazyUser],
["UserGroup", LazyUserGroup],
["XMark", LazyXMark],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
