import {UserRole} from '~/graphql/graphql';

export function useRole() {
  const user = useSofieUserWhenAuthenticated();

  const isManager = computed(() => [UserRole.Manager, UserRole.Developer].includes(user.value?.role));
  const isOrganizationManager = computed(() => user.value?.role === UserRole.OrganizationManager);
  const isReadOnly = computed(() => user.value?.role === UserRole.ReadOnly);
  const isContributor = computed(() => user.value?.role === UserRole.Contributor);

  const rolesOrder = [UserRole.ReadOnly, UserRole.Contributor, UserRole.OrganizationManager, UserRole.Manager];
  const currentUserRoleIndex = computed(() => rolesOrder.indexOf(user.value?.role));

  const formOptions = computed(() => rolesOrder.map((role, index) => ({
    value: role,
    label: {
      [UserRole.ReadOnly]: 'Read-only',
      [UserRole.Contributor]: 'Medewerker',
      [UserRole.OrganizationManager]: 'Organisatiebeheerder',
      [UserRole.Manager]: 'Beheerder',
    }[role],
    attrs: {
      disabled: index > currentUserRoleIndex.value,
    },
  })));

  return {
    isManager,
    isOrganizationManager,
    isReadOnly,
    isContributor,
    options: {
      [UserRole.ReadOnly]: 'Read-only',
      [UserRole.Contributor]: 'Medewerker',
      [UserRole.OrganizationManager]: 'Organisatiebeheerder',
      [UserRole.Manager]: 'Beheerder',
    },
    formOptions,
  };
}
